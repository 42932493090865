/*
 * @Author: liuhuazhou liuhuazhou@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: liuhuazhou liuhuazhou@xiaomi.com
 * @LastEditTime: 2024-06-20 14:50:49
 * @FilePath: /RetailApp/retail-global-h5/src/pages/Home/HomePage.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import globalStyles from '../global.less';

function HomePage() {

    const push = useNavigate();

    useEffect(() => {
        // 先跳转到零售通下载页面
        push('/RetailGlobal/Downloads');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div className={globalStyles['container']}></div>
}

export default memo(HomePage)
