/*
 * @Author: liuhuazhou liuhuazhou@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: liuhuazhou liuhuazhou@xiaomi.com
 * @LastEditTime: 2024-06-19 14:48:26
 * @FilePath: /RetailApp/retail-global-h5/src/utils/net.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import axios from "axios";
// @ts-ignore
import Notification from 'rmc-notification';
import "rmc-notification/assets/index.css"
import Toast from '../components/toast';
export let notification:any = null;
Notification.newInstance({
    transitionName: 'am-fade',
}, (n:any) => notification = n);
const Axios = axios.create();
// 请求拦截
Axios.interceptors.request.use(
    (config) => {
        // token认证写在这里
        return config;
    },
    (err) => {
        Promise.reject(err);
    }
);
// 响应拦截
Axios.interceptors.response.use(
    (config) => {
        return config;
    },
    (err) => {
        Toast.error('发生了小问题，请稍后再试');
        Promise.reject(err);
    }
);
// get封装
export function get(url:string, params = {}) {
    return new Promise((resolve, reject) => {
        Axios({
            url,
            params,
            method: "get",
        })
            .then((res) => {
                resolve(res);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
// post封装
export function post(url:string,  data = {}, needToast=true ,errMsg='发生了小问题，请稍后再试') {
    return new Promise((resolve, reject) => {
        Axios({
            url,
            method: "post",
            params:{},
            data,
        })
            .then((res) => {
                if(!res) return
                const result=res.data
                if (result.code !== 0 && needToast) {
                    Toast.error(errMsg || result.message);
                }
                resolve(result);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
const Service = { get, post };
export default Service
