import React, { useEffect } from "react";
import globalStyles from "../../../global.less";
import styles from "./index.less";
import { setTitleDesc } from '../../../../utils/tools'

const getSty = (str: string) => styles[str];
const fonSizeBold = (str: string, cla?: string) => (
  <span className={`${getSty("KeyPoint")} ${cla ? styles[cla] : ""}`}>
    {str}
  </span>
);

export default function ProtocolID() {
  useEffect(() => {
    setTitleDesc('Xiaomi Channel', '小米分销通')
  }, [])
  
  return (
    <div className={`${globalStyles["container"]} ${getSty("protocol-id")}`}>
      <h2 className={getSty("title-h2")}>Syarat & Ketentuan Xiaomi Channel </h2>
      <p className={getSty("explain-title")}>Platform Xiaomi Channel</p>
      <p className={getSty("explain-title")}>Syarat dan Ketentuan </p>
      <h5 className={getSty("explain")}>
        MOHON BACA SYARAT DAN KETENTUAN DENGAN SEKSAMA DAN PERHATIKAN KETENTUAN
        YANG DICETAK TEBAL DAN HURUF KAPITAL.
      </h5>
      <p className={getSty("explain")}>
        Dokumen ini berisi syarat dan ketentuan umum yang menjadi dasar Pembeli
        melakukan transaksi, berkonsultasi, dan berurusan dengan informasi
        transaksi dengan Penjual dengan menggunakan Platform Xiaomi Channel dan
        Fitur-fiturnya yang dimiliki, dioperasikan, bermerek, dan disediakan
        oleh Entitas Pemasok Xiaomi (didefinisikan di bawah ini).
      </p>
      <p className={getSty("explain")}>
        Syarat dan Ketentuan dibuat antara Pengguna dan Entitas Pemasok Xiaomi
      </p>
      <div className={getSty("item-box")}>
        <span>Definisi</span>
        <p className={getSty("title-explain")}>
          Untuk memungkinkan pemahaman dan penerimaan yang lengkap atas syarat
          dan ketentuan yang terkandung di sini, istilah-istilah berikut ini,
          dalam bentuk tunggal dan jamak, memiliki arti yang ditunjukkan di
          bawah ini:
        </p>
        <ol>
          <li>
            {fonSizeBold("Akun")} memiliki arti sebagaimana ditetapkan dalam
            klausul "Pendaftaran dan Login ke Platform Xiaomi Channel".
          </li>
          <li>
            {fonSizeBold("Afiliasi")} berarti, sehubungan dengan Entitas Pemasok
            Xiaomi, orang lain yang, secara langsung atau tidak langsung,
            memiliki atau Mengendalikan, atau dimiliki atau Dikendalikan oleh,
            atau berada di bawah Kendali yang sama dengan Entitas Pemasok Xiaomi
            ini. Beberapa Fitur mungkin didukung oleh Afiliasi dari Entitas
            Pemasok Xiaomi.
          </li>
          <li>
            {fonSizeBold("Informasi Rahasia")} berarti (1) setiap informasi atau
            data yang dibuat, diposting, diunggah, atau dikomunikasikan melalui
            Platform Xiaomi Channel; dan (2) setiap informasi atau data yang
            terkait dengan keberadaan, desain, tata letak, bangunan, fitur,
            konten, dll. dari Platform Xiaomi Channel, termasuk keberadaan dan
            ketentuan S&K.
          </li>
          <li>
            {fonSizeBold("Konten yang diunggah oleh Xiaomi")} memiliki arti
            sebagaimana ditetapkan dalam klausul "Konten yang Diunggah oleh
            Entitas Pemasok Xiaomi"
          </li>
          <li>
            {fonSizeBold("Pengendalian")} berarti kepemilikian, baik secara
            langsung maupun tidak langsung, kekuasaan untuk mengarahkan untuk
            menentukan arah manajemen dan kebijakan suatu entitas, baik melalui
            kepemilikan hak suara, kepentingan kontrak atau lainnya.
          </li>
          <li>
            {fonSizeBold("Peralatan")} berarti setiap produk yang diproduksi
            dan/atau didistribusikan oleh Entitas Pemasok Xiaomi dan/atau
            Afiliasi langsung dan tidak langsungnya dan/atau oleh anggota lain
            dalam grup Xiaomi, dengan merek, logo, label, tanda, atau papan nama
            Mi, Xiaomi, Redmi, dll.
          </li>
          <li>
            {fonSizeBold("Fitur")} berarti fitur yang disebutkan dalam klausa
            "Fitur".
          </li>
          <li>
            {fonSizeBold("Hukum")} berarti setiap dan semua undang-undang,
            hukum, tata cara, peraturan, kode, konstitusi, perjanjian, hukum
            umum, perintah pemerintah atau persyaratan lain atau aturan hukum
            dari otoritas pemerintah mana pun.
          </li>
          <li>
            {fonSizeBold("Pembeli")} berarti klien yang membeli Peralatan di
            Platform Xiaomi Channel, biasanya termasuk distributor dan pengecer
            independen. Karena login dan akses ke Platform Xiaomi Channel dibuat
            atas nama Pembeli oleh karyawan yang ditunjuk dan berwenang, maka
            setiap referensi ke "Pembeli" dalam S&K harus diartikan bahwa arti
            tersebut termasuk karyawan yang bekerja untuk Pembeli.
          </li>
          <li>
            {fonSizeBold("Personil")} berarti karyawan, perwakilan, agen,
            subkontraktor atau pihak ketiga lainnya yang terlibat atau ditunjuk
            oleh Pengguna termasuk Pembeli atau Penjual.
          </li>
          <li>
            {fonSizeBold("Penjual")} berarti penjual yang menggunakan Platform
            Xiaomi Channel untuk menjual Peralatan kepada Pembeli, dan termasuk
            Penjual Pihak Ketiga, biasanya adalah distributor jika pembeli
            adalah pengecer. Entitas Lokal Xiaomi juga dapat menjadi Penjual.
          </li>
          <li>
            {fonSizeBold("Vendor Pihak Ketiga")} berarti penjual yang
            menggunakan Platform Xiaomi Channel untuk menjual Peralatan kepada
            Pembeli, dan tidak termasuk Entitas Pemasok Xiaomi.
          </li>
          <li>
            {fonSizeBold('Syarat dan Ketentuan ("S&K")')} berarti kontrak yang
            mengatur hubungan antara Pengguna dan Entitas Pemasok Xiaomi.
          </li>
          <li>
            {fonSizeBold("Pengguna")} berarti orang yang menggunakan fitur-fitur
            Platform Xiaomi Channel untuk membeli dan menjual peralatan,
            termasuk Pembeli dan Penjual.
          </li>
          <li>
            {fonSizeBold("Platform Xiaomi Channel ")} berarti situs web, situs
            seluler, dan aplikasi seluler Platform Xiaomi Channel (disebut
            sebagai Platform), yang melaluinya Pembeli dapat mengakses dan
            menggunakan fitur.
          </li>
          <li>
            {fonSizeBold("Entitas Pemasok Xiaomi ")} berarti entitas apa pun
            dalam grup Xiaomi yang mungkin memasok Peralatan.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Fitur</span>
        <p className={getSty("title-explain")}>
          Platform Xiaomi Channel memiliki fitur-fitur yang memfasilitasi
          aktivitas pembelian Peralatan Pembeli, termasuk tetapi tidak terbatas
          pada:
        </p>
        <ol>
          <li>
            {fonSizeBold("Profil Pembeli ")}
            <p>
              Dengan menggunakan fitur ini, Pembeli dapat (1) melihat pada
              bagian "Akun" untuk informasi dasar mengenai informasi tersebut,
              seperti informasi pendaftaran bisnis, informasi pajak, informasi
              pemegang saham, informasi alamat pengiriman, informasi rekening
              bank, dan lampiran yang relevan; dan (2) melihat pada bagian
              "Akun" perjanjian/kontrak yang dilakukan antara Pembeli dan
              Entitas Pemasok Xiaomi yang terkait.
            </p>
          </li>
          <li>
            {fonSizeBold("Melihat Informasi Keuangan")}
            <p className={`${styles["mb-8"]} ${styles["mt-8"]}`}>
              Dengan menggunakan fitur ini, Pembeli dapat melihat nota kredit
              yang digunakan oleh Pembeli.
            </p>
            <p className={`${styles["mb-8"]} ${styles["mt-8"]}`}>
              {fonSizeBold(
                "UNTUK MENGHINDARI KERAGUAN, DATA DAN INFORMASI YANG  DITAMPILKAN PADA BAGIAN INI HANYA DIGUNAKAN SEBAGAI REFERENSI  UNTUK REKONSILIASI, JIKA PEMBELI MEMILIKI PERTANYAAN MENGENAI  DATA DAN INFORMASI YANG DITAMPILKAN, PEMBELI DAPAT  MENGHUBUNGI MANAJER PENJUALAN YANG DITUNJUK OLEH PENJUAL  TERKAIT. "
              )}
            </p>
          </li>
          <li>
            {fonSizeBold("Manajemen Pesanan Pembelian")}
            <p>
              Melalui fitur ini, Pembeli dapat (1) memeriksa situasi pesanan
              pembelian sebelumnya yang dilakukan dalam waktu tiga (3) bulan;
              (2) memeriksa nota pengiriman untuk pesanan pembelian yang
              dilakukan dalam waktu tiga (3) bulan; dan (3) melakukan pemesanan
              pembelian kepada Penjual terkait, untuk membeli Peralatan dengan
              mengisi dan mengirimkan pesanan pembelian online dengan formulir
              yang ditampilkan di Platform Xiaomi Channel.
            </p>
            <p>
              Saat melakukan pemesanan pembelian, Pembeli harus mengisi semua
              informasi yang dibutuhkan. Pesanan pembelian dikirim saat Pembeli
              mengirimkannya ke Penjual terkait dan diterima saat diterima oleh
              karyawan pertama Penjual yang bertanggung jawab atas penjualan
              Peralatan.
            </p>
            <p>
              Jika pesanan pembelian ditolak oleh Penjual, pemberitahuan
              tertulis akan dikirimkan kepada Pembeli atau Pembeli akan
              dihubungi oleh manajer penjualan yang ditunjuk oleh Penjual
              terkait.
            </p>
            <p>
              Jika Oembeli telah melakukan pemesanan di Plaatform Xiaomi Channel
              untuk pembelian Peralatan yang dijual oleh Entitas Lokal Xiaomi
              dan Entitas Lokal Xiaomi telah menerima pesanan tersebut, hal ini
              akan menjadi hubungan kontraktual yang dibuat secara langsung oleh
              Pembeli dan Entitas Lokal Xiaomi.
            </p>
            <p>
              Di sisi lain, di mana Pembeli telah melakukan pemesanan di
              Platfrom Xiaomi Channel untuk pembelian Peralatan yang dijual oleh
              Vendor Pihak Ketiga dan Vendor Pihak Ketiga tersebut telah
              menerima hal yang sama, hal ini akan menjadi hubungan kontrak yang
              dibuat secara langsung antara Pembeli dan Vendor Pihak Ketiga.
              Jika kontrak dibuat secara langsung antara Pembeli dan Vendor
              Pihak Ketiga, Entitas Lokal Xiaomi bukan merupakan pihak dalam
              kontrak atau perjanjian lain antara Pembeli dan Vendor Pihak
              Ketiga dan tidak memiliki kewajiban apa pun sehubungan dengan
              perjanjian tersebut. Para pihak dalam kontrak atau perjanjian lain
              tersebut sepenuhnya bertanggung jawab atas perjanjian di antara
              mereka, daftar Peralatan, garansi pembelian, dan sejenisnya.
            </p>
            <p>
              Pesanan pembelian dapat dibatalkan oleh Pembeli sebelum diterima
              oleh Penjual. Jika pesanan pembelian hanya dilakukan sebagian atau
              tidak dilakukan sama sekali, Pembeli atau Penjual dapat memulai
              prosedur penghentian pesanan pembelian.
              {fonSizeBold(
                "JIKA PENJUAL MEMULAI PROSEDUR PENGAKHIRAN, SEBUAH EMAIL AKAN DIKIRIMKAN KEPADA PEMBELI, PEMBELI DAPAT MENERIMA ATAU MENOLAK UNTUK MENGAKHIRI PESANAN PEMBELIAN, JIKA PEMBELI TIDAK MENANGGAPI EMAIL TERSEBUT DALAM WAKTU TIGA (3) HARI KERJA, MAKA PESANAN PEMBELIAN YANG BERSANGKUTAN AKAN DIANGGAP TELAH DIAKHIRI OLEH KEDUA BELAH PIHAK. "
              )}
            </p>
            <p>
              Jika perubahan informasi Pembeli memengaruhi kinerja pesanan
              pembelian, Entitas Pemasok Xiaomi berhak untuk menangguhkan
              pelaksanaan pesanan pembelian untuk waktu yang wajar atau
              mengambil tindakan lain yang sesuai tanpa menanggung biaya atau
              tanggung jawab tambahan.
            </p>
          </li>
          <div className={styles["mg-42"]}>
            {fonSizeBold(
              "UNTUK MENGHINDARI KERAGUAN, PEMBELI SETUJU BAHWA SYARAT DAN KETENTUAN YANG TERKANDUNG DALAM FORMULIR PESANAN PEMBELIAN PADA PLATFORM XIAOMI CHANNEL ADALAH YANG DIDISKUSIKAN DENGAN ENTITAS PEMASOK XIAOMI YANG SESUAI, DAN JIKA PEMBELI MELAKUKAN PEMESANAN PEMBELIAN MELALUI FITUR INI, PEMBELI MENERIMA DAN AKAN TERIKAT OLEH SYARAT DAN KETENTUAN PADA PESANAN PEMBELIAN. "
            )}
          </div>
          <li>
            {fonSizeBold("Manajemen Distribusi")}
            <p>
              Melalui fitur ini, Pembeli dapat mengisi dan mengelola pesanan
              distribusinya yang menunjukkan penerimaan, penjualan, atau
              pengembalian Peralatan.
            </p>
          </li>
          <li>
            {fonSizeBold("Penerimaan Pesan")}
            <p>
              Melalui fitur ini, Pembeli dapat menerima pesan dari Penjual atau
              Entitas Pemasok Xiaomi.
            </p>
          </li>
          <li>
            {fonSizeBold("Fitur Lainnya")}
            <p>
              Pembeli juga dapat memeriksa beberapa data bisnis agregat
              berdasarkan fungsi-fungsi di atas.
            </p>
            <div className={styles["mg-42"]}>
              Akses dan penggunaan Platform Xiaomi Channel dan fitur-fiturnya
              adalah gratis untuk Pembeli yang secara bebas memutuskan untuk
              mengakses dan menggunakan Platform Xiaomi Channel.
            </div>
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Penerimaan S&K</span>
        <ol>
          <li>
            Sebelum menggunakan Fitur, Pengguna diharuskan untuk membaca S&K
            dengan seksama, memahami sepenuhnya semua bagiannya, termasuk
            ketentuan-ketentuan Sanggahan, Batasan Tanggung Jawab, tanggung
            jawab Pengguna, dan Hukum yang Mengatur serta Yurisdiksi, dan
            memilih untuk menerima atau tidak menerima S&K atas kebijakan
            Pengguna sendiri.
          </li>
          <li>
            Dengan melakukan pendaftaran, login, penggunaan Fitur atau tindakan
            lainnya, Pengguna mengakui bahwa Pengguna telah membaca dan memahami
            sepenuhnya, serta setuju untuk menerima dan terikat oleh S&K.
            Entitas Pemasok Xiaomi dapat menangguhkan atau menghentikan
            penyediaan Fitur kepada Pengguna jika Pengguna tidak mematuhi
            persyaratan dan/atau kebijakan di Platform Xiaomi Channel.
          </li>
          <li>
            {fonSizeBold("Kebijakan Privasi ", "underline")} dan
            {fonSizeBold("dokumen mengikat lainnya", "underline")} yang tersedia
            untuk Pengguna di Platform Xiaomi Channel, akan menjadi perjanjian
            tambahan untuk S&K dengan penerapan dan validitas hukum yang sama.
            Jika Pengguna menggunakan Fitur, hal tersebut akan dianggap sebagai
            persetujuan tersirat terhadap S&K dan perjanjian tambahan di atas.
          </li>
          <li>
            S&K dan perjanjian tambahan lainnya, jika diperlukan, dapat direvisi
            dari waktu ke waktu oleh Entitas Pemasok Xiaomi. Dengan menerima
            S&K, Pengguna setuju untuk menerima semua batasan, termasuk menerima
            hak Entitas Pemasok Xiaomi untuk mengubah S&K dan perjanjian
            tambahannya dari waktu ke waktu, dengan atau tanpa pemberitahuan
            tambahan kepada Pengguna. Pengguna dapat membuka Platform Xiaomi
            Channel setiap saat untuk memeriksa S&K terbaru dan perjanjian
            tambahannya yang akan berlaku efektif sejak dipublikasikan. Jika
            Pengguna tidak dapat menerima konten apa pun yang diubah oleh
            Entitas Penyalur Xiaomi, Pengguna harus, atas kebijakannya sendiri,
            berhenti menggunakan Fitur. Dengan terus menggunakan Fitur, Pengguna
            setuju untuk menerima dan akan terikat oleh ketentuan S&K dan
            perjanjian tambahan yang telah diubah.
          </li>
          <li>
            S&K dan perjanjian tambahannya, perjanjian jual beli yang relevan,
            dan dokumen lain yang dirujuk dalam S&K merupakan perjanjian yang
            mengikat secara hukum antara Pengguna dan Entitas Pemasok Xiaomi
            terkait dan sehubungan dengan penggunaan Platform Xiaomi Channel dan
            fitur. Jika Pengguna tidak menerima S&K, perjanjian tambahannya
            dan/atau catatan lain, pemberitahuan hukum, informasi yang
            diterbitkan, atau dirujuk di dalamnya, Pengguna tidak boleh meminta
            Akun, mendaftar, masuk, atau menggunakan Platform Xiaomi Channel
            atau Fitur terkait, yang akan diputuskan berdasarkan kebijakan
            Pengguna sendiri.
          </li>
          <li>
            Entitas Pemasok Xiaomi berhak tetapi tidak berkewajiban untuk
            mengubah, atas kebijakannya sendiri dan dari waktu ke waktu, bahkan
            setelah pendaftaran Pengguna, antarmuka grafis Platform Xiaomi
            Channel, Fitur, konten yang tersedia di Platform Xiaomi Channel dan
            organisasinya, serta Fitur lain yang menjadi ciri fungsionalitas dan
            manajemen Platform Xiaomi Channel, dengan mengkomunikasikan kepada
            Pengguna petunjuk relatif, jika diperlukan. Setiap versi revisi dari
            Platform Xiaomi Channel, pengenalan Fitur baru, dan pembaruan atau
            perubahan lain dari Fitur yang ada akan diatur oleh S&K dan
            perjanjian tambahannya.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Registrasi dan Login ke Platform Saluran Xiaomi </span>
        <ol>
          <li>
            Dengan maksud untuk menggunakanPlatform Xiaomi Channel dan
            Fitur-fiturnya, Pengguna dapat meminta dan mendapatkan akun atau
            akun-akun untuk Platform Xiaomi Channel dengan mengirimkan
            permintaan resmi kepada Entitas Pemasok Xiaomi yang sesuai
            (selanjutnya disebut "{fonSizeBold("Akun")}"), yang
            sesuai/berhubungan dengan seluruh atau sebagian Fitur dan akan
            dioperasikan oleh karyawan Pengguna yang ditunjuk dan diberi
            wewenang. Untuk tujuan ini, Pengguna diharuskan untuk menyebutkan
            nama lengkap karyawan mereka yang berwenang untuk mengakses Akun
            beserta email dan/atau informasi lain yang diperlukan. Setelah
            diaktifkan, Akun akan diberikan kepada Pengguna oleh Entitas Pemasok
            Xiaomi yang bersangkutan bersama dengan kata sandi awal Akun yang
            sama. Selama akses pertama ke Akun, kata sandi awal perlu diubah
            oleh Pengguna.
          </li>
          <li>
            Untuk masuk ke Platform Xiaomi Channel dan memanfaatkan Fitur,
            Pengguna dan/atau karyawan resmi Pengguna harus memasukkan
            kredensial masuk (alamat email dan kata sandi yang dipilih, dll.).
            Akun hanya dapat digunakan oleh karyawan Pengguna yang ditunjuk dan
            diberi wewenang.
          </li>
          <li>
            Pengguna dengan ini berkomitmen dan berjanji bahwa Pengguna
            bertanggung jawab atas keamanan informasi dan kata sandi Akunnya dan
            harus bertanggung jawab secara hukum atas aktivitas di bawah
            Akunnya. Setiap login dan akses ilegal di Platform Xiaomi Channel
            yang dilakukan oleh orang yang tidak berwenang dari Pengguna akan
            menjadi tanggung jawab Pengguna sepenuhnya dan Pengguna harus
            mengganti kerugian Entitas Pemasok Xiaomi serta Afiliasinya terhadap
            setiap kerugian, kerusakan dan kompensasi kepada pihak ketiga yang
            terkena dampak karena terjadinya akses tersebut.
          </li>
          <li>
            Pengguna juga memahami dan dengan ini mengakui dan menyetujui bahwa
            Entitas Pemasok Xiaomi memiliki hak untuk mengubah, memperbarui,
            menangguhkan, membatasi, mengakhiri, atau mencabut Akun kapan saja
            untuk sementara atau selamanya tanpa pemberitahuan sebelumnya dan
            tanpa tanggung jawab apa pun atas kerugian atau kerusakan yang
            timbul karenanya.
          </li>
          <li>
            {fonSizeBold("Kebijakan Privasi")}. Entitas Pemasok Xiaomi dapat
            mengumpulkan dan memproses data pribadi dari Pengguna, dan/atau
            Personilnya, untuk tujuan berkomunikasi tentang, dan mengembangkan,
            hubungan bisnis timbal balik para pihak, mematuhi kewajiban hukum,
            dan secara umum memberlakukan ketentuan S&K. Setiap data pribadi
            tersebut akan dikumpulkan dan diproses sesuai dengan Kebijakan
            Privasi, yang dapat dikonsultasikan sebelum mendapatkan Akun, saat
            masuk ke Platform Xiaomi Channel untuk pertama kalinya dan/atau di
            bagian bawah halaman beranda Platform Xiaomi Channel.
          </li>
          <div className={styles["indentation-w"]}>
            {fonSizeBold(
              "DENGAN MENERIMA KEBIJAKAN PRIVASI, PENGGUNA MENGKONFIRMASI PERSETUJUANNYA TERHADAP PENGUMPULAN DAN PENGOLAHAN DATA PRIBADI YANG DIBERIKAN OLEH ENTITAS PEMASOK XIAOMI ATAU AFILIASINYA. "
            )}
          </div>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Pembatalan dan Penutupan Akun</span>
        <ol>
          <li>
            Jika Pengguna ingin menonaktifkan atau membatalkan Akun, Pengguna
            dapat mengirimkan komunikasi tertulis kepada narahubung yang
            ditunjuk oleh Entitas Pemasok Xiaomi terkait.
          </li>
          <li>
            Jika terjadi pelanggaran oleh Pengguna terhadap S&K, perjanjian
            tambahannya dan/atau ketentuan hukum yang berlaku, Entitas Pemasok
            Xiaomi berhak untuk menangguhkan atau menutup Akun yang bersangkutan
            setiap saat dan tanpa pemberitahuan.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span className={styles["line-1-4"]}>
          Hubungan Kontraktual antara Pembeli dan Penjual
        </span>
        <ol>
          <li>
            Hubungan kontraktual pembelian dan penjualan antara Pembeli dan
            Penjual terkait akan diatur oleh S&K, perjanjian tambahannya,
            perjanjian pasokan dan pembelian, dan pesanan pembelian yang
            dilakukan oleh dan di antara mereka, yang mencakup pesanan pembelian
            online yang dilakukan oleh Pembeli dan disetujui oleh Penjual
            terkait melalui Platform Xiaomi Channel.
          </li>
          <li>
            Pembeli mengakui dan menyetujui bahwa pesanan pembelian online yang
            dilakukan melalui Platform Xiaomi Channel dan disetujui oleh Penjual
            terkait secara elektronik memenuhi persyaratan hukum apa pun bahwa
            perjanjian, kontrak dan pesanan pembelian tersebut harus dalam
            bentuk tertulis dan pesanan pembelian ini memiliki kekuatan penuh
            yang akan dilakukan sepenuhnya oleh Pembeli.
          </li>
          <li>
            Apabila terjadi pertentangan, dokumen berikut akan berlaku sesuai
            dengan urutan: S&K beserta perubahan dan perjanjian tambahannya,
            perjanjian pasokan dan pembelian, surat otorisasi, dan pesanan
            pembelian. Urutan ini tidak berlaku jika konflik menyangkut
            pengiriman, layanan dan dukungan, dan definisi wilayah, di mana
            ketentuan pesanan pembelian yang tercermin dalam setiap pesanan
            pembelian akan berlaku.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span className={styles["line-1-4"]}>
          Konten yang Diunggah oleh Entitas Pemasok Xiaomi
        </span>
        <ol>
          <li>
            Untuk beberapa bagian Platform Xiaomi Channel, konten mengenai
            Pengguna dapat diposting dan diunggah oleh Entitas Pemasok Xiaomi
            sesuai dengan dokumen yang disediakan oleh Pengguna (
            {fonSizeBold("Konten yang Diunggah oleh Xiaomi")}). Untuk Konten
            yang Diunggah oleh Xiaomi, seperti informasi pendaftaran Pengguna,
            Pengguna harus memeriksa, mengkonfirmasi dan memastikan keaslian,
            legalitas, keakuratan dan keabsahannya.
          </li>
          <li>
            Jika terjadi ketidakakuratan atau perubahan pada Konten yang
            Diunggah oleh Xiaomi, Pengguna harus memberi tahu Entitas Pemasok
            Xiaomi yang terkait tepat waktu, jika tidak, Pengguna harus
            menanggung semua risiko dan kerugian yang timbul darinya.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Pernyataan dan Jaminan Pengguna</span>
        <ol>
          <li>
            Pengguna menyatakan, menjamin dan menyetujui bahwa (1) Pengguna
            memiliki kuasa dan wewenang penuh untuk menerima S&K dan perjanjian
            tambahannya, untuk memberikan lisensi dan otorisasi, serta untuk
            melaksanakan kewajiban-kewajiban di bawah ini; dan (2) akses dan
            penggunaan Platform Xiaomi Channel dan Fitur hanya untuk tujuan
            bisnis dan sesuai dengan S&K dan perjanjian tambahannya.
          </li>
          <li>
            Pengguna sedang atau akan diminta untuk memberikan informasi atau
            materi tentang entitas atau bisnisnya sebagai bagian dari proses
            pendaftaran di Platform Xiaomi Channel untuk akses dan penggunaan
            Fitur atau Akun. Pengguna menyatakan, menjamin dan menyetujui bahwa
            (1) informasi dan materi tersebut baik yang disampaikan sebelum,
            selama proses pendaftaran atau setelahnya selama kelanjutan
            penggunaan Platform Xiaomi Channel atau Fitur adalah benar, akurat,
            terkini, lengkap dan tidak salah, menyesatkan atau menipu; dan (2)
            Pengguna akan menjaga dan segera mengubah semua informasi dan materi
            agar tetap benar, akurat, terkini, dan lengkap.
          </li>
          <li>
            Pengguna menyatakan, menjamin dan menyetujui bahwa Pengguna akan
            menggunakan Platform Xiaomi Channel dan Fitur dengan itikad baik dan
            mematuhi semua hukum yang berlaku, termasu hukum dimana Pengguna dan
            Entitas Pemasok Xiaomi berada, perjanjian dan kebijakan pengguna
            Microsoft serta S&K dan perjanjian tambahannya, bertanggung jawab
            penuh atas aktivitas di bawah Akun Pengguna, dan tidak akan
            menggunakan Platform Xiaomi Channel untuk menipu Entitas Pemasok
            Xiaomi dan Afiliasinya atau terlibat dalam aktivitas yang melanggar
            hukum lainnya.
          </li>
          <li>
            Pengguna mengaku bahwa membagikan Akunnya dengan orang lain atau
            mengizinkan beberapa pengguna di luar entitas bisnisnya untuk
            menggunakan Akun tersebut dapat menyebabkan kerusakan yang tidak
            dapat diperbaiki pada Entitas Pemasok Xiaomi dan Afiliasinya, dan
            menyatakan, menjamin dan setuju untuk menjaga dengan baik informasi
            pendaftaran dan kredensial login di Platform Xiaomi Channel dan
            bertanggung jawab atas semua aktivitas di bawah akunnya.
          </li>
          <li>
            Pengguna menyatakan, menjamin dan menyetujui bahwa Pengguna tidak
            akan menggunakan Akun dan kata sandi Pengguna laind ann tidak akan
            membuat, mengunggah, atau memposting informasi apa pun atas nama
            orang lain. Pengguna setuju untuk segera memberi tahu Entitas
            Pemasok Xiaomi setelah Pengguna mencurigai orang lain menggunakan
            kata sandi atau Akunnya dengan mengirim email ke
            <a href="mailto:robot@xiaomi.com"> robot@xiaomi.com</a>.
          </li>
          <li>
            Pengguna menyatakan, menjamin dan menyetujui bahwa Pengguna berjanji
            untuk membebaskan Entitas Pemasok Xiaomi dan Afiliasinya dari segala
            kerusakan, ganti rugi, dan/atau pinalti yang diakibatkan oleh
            dan/atau dengan cara apa pun yang terkait dengan pelanggaran yang
            dilakukan oleh Pengguna terhadap aturan pendaftaran Platform Xiaomi
            Channel, persyaratan penyimpanan kredesial login dan aturan
            penggunaan atau tindakan Pengguna lainnya; jika tidak, Entitas
            Pemasok Xiaomi berhak untuk menangguhkan Akun dan akses ke Platform
            Xiaomi Channel dan Pengguna akan sepenuhnya menanggung semua
            tanggung jawab hukum. Xiaomi tidak bertanggung jawab atau
            berkewajiban atas klaim atau kerugian apa pun yang timbul dari
            tindakan Pengguna.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${styles["bold-box"]}`}>
        <span>Sanggahan </span>
        <ol>
          <li>
            ENTITAS PEMASOK XIAOMI DAN AFILIASINYA TIDAK AKAN MEMIKUL TANGGUNG
            JAWAB HUKUM APA PUN DALAM KEADAAN YANG DISEBUTKAN DI BAWAH INI:
          </li>
          <div className={styles["disorderly"]}>
            KETERBUKAAN INFORMASI PENGGUNA YANG DIMINTA OLEH DEPARTEMEN
            PEMERINTAH ATAU HUKUM YANG BERLAKU.
          </div>
          <div className={styles["disorderly"]}>
            PENGUNGKAPAN INFORMASI PRIBADI YANG DISEBABKAN OLEH TINDAKAN
            PENGGUNA.
          </div>
          <div className={styles["disorderly"]}>
            PENGUNGKAPAN INFORMASI YANG DILARANG MENURUT HUKUM YANG BERLAKU,
            TERMASUK HUKUM DI MANA PENGGUNA DAN ENTITAS PEMASOK XIAOMI TERKAIT
            BERADA.
          </div>
          <div className={styles["disorderly"]}>
            KEADAAN APA PUN YANG DISEBABKAN OLEH PENANGGUHAN, KERUSAKAN DAN
            SEBAGAINYA YANG DIAKIBATKAN OLEH PERETASAN, INVASI VIRUS,
            PEMBLOKIRAN KARENA KONTEN ILEGAL DAN MELECEHKAN, KONTROL PEMERINTAH,
            ATAU ALASAN LAIN APA PUN YANG TERKAIT DENGAN JARINGAN, TEKNIK, JALUR
            KOMUNIKASI DAN MANAJEMEN KEAMANAN INFORMASI.
          </div>
          <div className={styles["disorderly"]}>
            KERUGIAN PADA PENGGUNA ATAU PIHAK KETIGA YANG DITIMBULKAN OLEH PIHAK
            KETIGA LAINNYA, SEPERTI TERGANGGUNGNYA JARINGAN KOMUNIKASI, MASALAH
            TEKNIS, JARINGAN DAN KOMPUTER YANG TERGANGGU, SERTA KEADAAN KAHAR
            LAINNYA.
          </div>
          <div className={styles["disorderly"]}>
            RISIKO YANG BERASAL DARI ANCAMAN, PENCEMARAN, INFORMASI YANG
            MENYEBABKAN KETIDAKPASTIAN ATAU PELANGGARAN MELALUI PLATFORM XIAOMI
            CHANNEL.
          </div>
          <div className={styles["disorderly"]}>
            KERUGIAN, KLAIM, TUNTUTAN HUKUM YANG DISEBABKAN OLEH KELALAIAN
            DAN/ATAU KEGAGALAN PENGGUNA DALAM SALAH SATU KETENTUAN BERDASARKAN
            S&K INI.
          </div>
          <li>
            PLATFORM XIAOMI CHANNEL DAN FITUR-FITURNYA DISEDIAKAN “SEBAGAIMANA
            ADANYA” DAN “SEBAGAIMANA TERSEDIA”, DAN ENTITAS PEMASOK XIAOMI
            DAN/ATAU AFILIASINYA TIDAK MEMBERIKAN JAMINAN TERSURAT ATAU TERSIRAT
            SEHUBUNGAN DENGAN PLATFORM XIAOMI CHANNEL DAN FITUR-FITURNYA TENTANG
            KETEPATAN WAKTU, KEAMANAN DAN AKURASINYA SECARA TEGAS, SECARA
            TERSIRAT ATAU DALAM BENTUK LAINNYA. ENTITAS PEMASOK XIAOMI DAN/ATAU
            AFILIASINYA TIDAK MENYEDIAKAN JAMINAN APA PUN BAHWA PLATFORM XIAOMI
            CHANNEL DAN FITUR-FITURNYA AKAN MEMENUHI KEBUTUHAN PENGGUNA ATAU
            TIDAK AKAN PERNAH TERGANGGU ATAU BEBAS DARI GANGGUAN ATAU BEBAS DARI
            VIRUS ATAU BUG.
          </li>
          <li>
            ENTITAS PEMASOK XIAOMI DAN AFILIASINYA AKAN BERUSAHA MEMASTIKAN
            BAHWA PLATFORM XIAOMI CHANNEL DAN FITUR-FITURNYA TERSEDIA SECARA
            TERUS MENERUS SELAMA 24 JAM SETIAP HARI, NAMUN TIDAK DAPAT
            DIPERTANGGUNGJAWABKAN DENGAN CARA APA PUN JIKA, KARENA ALASAN APA
            PUN, PLATFORM DAN/ATAU FITUR-FITURNYA TIDAK DAPAT DIAKSES DAN/ATAU
            BEROPERASIONAL SETIAP SAAT ATAU SELAMA PERIODE APA PUN. AKSES KE
            PLATFORM XIAOMI CHANNEL DAN/ATAU FITUR-FITURNYA MUNGKIN DITANGGUHKAN
            SEMENTARA JIKA TERJADI GAGALNYA SISTEM, PEMELIHARAAN, PERBAIKAN ATAU
            KARENA ALASAN YANG SAMA SEKALI TIDAK BERHUBUNGAN DENGAN KEINGINAN
            ENTITAS PEMASOK XIAOMI DAN AFILIASINYA ATAU KARENA PERISTIWA KEADAAN
            KAHAR, DALAM HAL INILAH ENTITAS PEMASOK XIAOMI AKAN BERUSAHA UNTUK
            MENGINFORMASIKAN PENGGUNA.
          </li>
          <li>
            DALAM KEADAAN APA PUN, ENTTITAS PEMASOK XIAOMI DAN/ATAU AFILIASINYA
            TIDAK AKAN BERTANGGUNG JAWAB ATAS KERUGIAN TIDAK LANGSUNG,
            KONSEKUENSIAL, TIDAK SENGAJA, TIDAK DIRENCANAKAN, KECELAKAAN, LUAR
            BIASA ATAU KERUGIAN HUKUM, TERMASUK KEHILANGAN LABA YANG DISEBABKAN
            OLEH PENGGUNAAN PLATFORM XIAOMI CHANNEL ATAU FITUR-FITURNYA.
            PENGGUNA MENANGGUNG SEMUA RISIKO DARI PENGGUNAAN SISTEM KOMPUTER DAN
            PANGKALAN DATA SELULER MELALUI PLATFORM XIAOMI CHANNEL ATAU
            FITUR-FITURNYA.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${styles["bold-box"]}`}>
        <span>Pembatasan Tanggung Jawab</span>
        <ol>
          <li>
            ENTITAS PEMASOK XIAOMI TIDAK AKAN BERTANGGUNG JAWAB KEPADA PENGGUNA,
            KECUALI DALAM KASUS KESALAHAN YANG DILAKUKAN DENGAN SENGAJA ATAU
            KELALAIAN BESAR, ATAS KETERLAMBATAN ATAU KERUGIAN YANG BERHUBUNGAN
            DENGAN PENGGUNAAN INTERNET DI LUAR KENDALINYA SENDIRI ATAU
            PENGGUNAAN PEMASOKNYA.
          </li>
          <li>
            SELANJUTNYA, ENTITAS PEMASOK XIAOMI TIDAK AKAN BERTANGGUNG JAWAB
            ATAS KERUGIAN, KERUSAKAN DAN BIAYA YANG DITIMBUKAN OLEH PENGGUNA
            SEBAGAI AKIBAT KEGAGALAN MENJALAN S&K KARENA ALASAN YANG TIDAK DAPAT
            DITANGGUNG OLEH ENTITAS PEMASOK XIAOMI DAN AFILIASINYA.
          </li>
          <li>ENTITAS PEMASOK XIAOMI TIDAK AKAN BERTANGGUNG JAWAB ATAS:</li>
          <div className={styles["disorderly"]}>
            SETIAP KERUGIAN PELUANG BISNIS DAN KERUGIAN LAIN, MESKIPUN TIDAK
            LANGSUNG, YANG MUNGKIN DIDERITA OLEH PENGGUNA YANG BUKAN AKIBAT
            LANGSUNG DARI PELANGGARAN S&K OLEH ENTITAS PEMASOK XIAOMI;
          </div>
          <div className={styles["disorderly"]}>
            PENGGUNAAN PLATFORM XIAOMI CHANNEL YANG MELANGGAR HUKUM, TIDAK BENAR
            ATAU TIDAK SESUAI OLEH PENGGUNA ATAU PIHAK KETIGA.
          </div>
          <li>
            DALAM HAL ENTITAS PEMASOK XIAOMI BERTANGGUNG JAWAB ATAS PELANGGARAN
            S&K, TANGGUNG JAWABNYA AKAN TERBATAS PADA KERUSAKAN YANG SECARA UMUM
            DAPAT DIPERKIRAKAN OLEH ENTITAS PEMASOK XIAOMI PADA SAAT
            PENANDATANGANAN S&K KARENA KEADAAN YANG DIKETAHUI PADA SAAT ITU.
            TERLEPAS DARI HAL DI ATAS, KERUGIAN TERSEBUT TIDAK BOLEH LEBIH TNGGI
            DARI 100 USD (ATAU NILAI MATA UANG LOKAL YANG SETARA).
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Kewajiban Pengguna</span>
        <ol>
          <li>
            Pengguna sepenuhnya bertanggung jawab atas setiap pelanggaran
            terhadap kewajibannya dalam:
          </li>
          <div className={styles["disorderly"]}>
            S&K dan perjanjian tambahannya.
          </div>
          <div className={styles["disorderly"]}>
            Hukum yang berlaku di yurisdiksi yang relevan, termasuk yurisdiksi
            tempat Pengguna berdomisili atau tempat Pengguna mengakses dan
            menggunakan Platform Xiaomi Channel, atau tempat Entitas Pemasok
            Xiaomi berkedudukan.
          </div>
          <div className={`${styles["mt-12"]}`}>
            {fonSizeBold(
              "UNTUK KONSEKUENSI PELANGGARAN APA PUN, TERMASUK KERUGIAN ATAU KERUSAKAN APA PUN YANG MUNGKIN DIALAMI ATAU DIDERITA OLEH ENTITAS PEMASOK XIAOMI, AFILIASINYA, ATAU PIHAK KETIGA MANAPUN, ENTITAS PEMASOK XIAOMI DAN AFILIASINYA TIDAK BERTANGGUNG JAWAB KEPADA PENGGUNA ATAU PIHAK KETIGA MANA PUN SEHUBUNGAN DENGAN PELANGGARAN TERSEBUT. "
            )}
          </div>
          <div className={`${styles["mt-12"]} ${styles["mb-8"]}`}>
            {fonSizeBold(
              "PENGGUNA HARUS MEMBEBASKAN/MEMASTIKAN ENTITAS PEMASOK XIAOMI YANG BERKAITAN DAN AFILIASINYA BEBAS DARI TANGGUNG JAWAB ATAU KEWAJIBAN APA PUN YANG TIMBUK DARI TINDAKAN PENGGUNA. "
            )}
          </div>
          <li>
            Pengguna tidak boleh menggunakan Platform Xiaomi Channel atau Fitur
            untuk terlibat dalam perilaku atau aktivitas apa pun yang disebutkan
            di bawah ini:
          </li>
          <div className={styles["disorderly"]}>
            Mengunggah atau melepaskan virus, worm, atau malware untuk merusak
            atau mengubah sistem komputer atau data.
          </div>
          <div className={styles["disorderly"]}>
            Mengumpulkan informasi atau data orang lain, seperti alamat email,
            tanpa otorisasi dan persetujuan langsung dari mereka dan sesuai
            dengan hukum yang berlaku.
          </div>
          <div className={styles["disorderly"]}>
            Menonaktifkan koneksi jaringan Platform Xiaomi Channel, menyebabkan
            Platform Xiaomi Channel kelebihan beban lalu lintas, mengganggu atau
            merusak server dan koneksi dengan cara lain.
          </div>
          <div className={styles["disorderly"]}>
            Mencoba mengunjungi Platform Xiaomi Channel, server kami atau
            koneksi Platform Xiaomi Channel tanpa izin.
          </div>
          <div className={styles["disorderly"]}>
            Menganggu atau merusak penggunaan normal Fitur oleh Pengguna lain.
          </div>
          <div className={styles["disorderly"]}>
            Terlibat dalam tindakan yang melanggar hukum berdasarkan hukum yang
            berlaku, termasuk hukum dimana Pengguna dan Entitas Pemasok Xiaomi
            terkait berada.
          </div>
          <div
            className={`${styles["mt-12"]} ${styles["mb-12"]}  ${styles["inherit-w"]}`}
          >
            {fonSizeBold(
              "PENGGUNA AKAN MENANGGUNG TANGGUNG JAWAB APA PUN ATAS HAL TERSEBUT, MEMBEBASKAN ENTITAS PEMASOK XIAOMI DAN AFILIASINYA DARI KERUGIAN APA PUN. "
            )}
          </div>
          <li>
            Pengguna setuju untuk menggunakan Platform atau Fitur Xiaomi Channel
            secara sah, tidak membahayakan dan akan menanggung segala gugatan,
            keluhan, kehilangan, kerusakan, tanggung jawab, biaya dan ongkos
            (termasuk tetapi tidak terbatas pada biaya pengacara) termasuk klaim
            pihak ketiga, yang disebabkan oleh penggunaan Platform atau Fitur
            Xiaomi Channel dan pelanggaran S&K.
          </li>
          <div
            className={`${styles["mt-8"]} ${styles["mb-8"]} ${styles["indentation-w"]}`}
          >
            Entitas Pemasok Xiaomi dan/atau Afiliasinya memiliki hak eksklusif
            untuk melakukan pembelaan dan hak untuk menuntut ganti rugi.
          </div>
          <li>
            Pengguna tidak akan melakukan rekonsiliasi secara sepihak ketika
            Pengguna dan Entitas Pemasok Xiaomi yang terkait secara bersama-sama
            mengajukan gugatan terhadap pihak ketiga mana pun tanpa persetujuan
            tertulis dari Entitas Pemasok Xiaomi. Entitas Pemasok Xiaomi akan
            secara wajar memberitahukan kepada Pengguna mengenai gugatan atau
            tindakan hukum tersebut.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Kerahasiaan</span>
        <ol>
          <li>
            Pengguna bersama dengan personilnya harus selalu menjaga kerahasiaan
            dan tidak boleh menggunakan atau mengungkapkan Informasi Rahasia apa
            pun untuk tujuan apa pun selain dari pelaksanaan atau penegakan S&K
            dan/atau hak dan kewajiban lain yang berkaitan dengan Platform
            Xiaomi Channel dan Fitur, kecuali dengan persetujuan tertulis
            sebelumnya dari Entitas Pemasok Xiaomi atau Afiliasinya.
          </li>
          <li>
            Pengguna harus mengambil langkah-langkah keamanan yang ketat untuk
            melindungi Informasi Rahasia, termasuk namun tidak terbatas pada
            membangun sistem untuk menjaga kerahasiaan, melatih semua orang yang
            relevan yang dapat memperoleh akses ke Informasi Rahasia,
            menandatangani perjanjian kerahasiaan dengan mereka, dan menekankan
            kerahasiaan Informasi Rahasia, langkah-langkah keamanan, dan
            tanggung jawab atas pelanggaran perjanjian tersebut.
          </li>
          <li>
            Kecuali jika ada persetujuan tertulis sebelumnya dari Entitas
            Pemasok Xiaomi dan/atau Afiliasinya, Pengguna tidak boleh merekayasa
            balik, mendekompilasi, atau membongkar Informasi Rahasia apa pun
            (berwujud atau tidak berwujud) yang diungkapkan kepadanya dan tidak
            boleh menghapus, mencetak ulang, atau merusak pemberitahuan hak
            cipta, merek dagang, logo, legenda, atau pemberitahuan kepemilikan
            lainnya dari dokumen asli atau salinan Informasi Rahasia yang
            diungkapkan oleh Entitas Pemasok Xiaomi dan/atau afiliasinya.
          </li>
          <li>
            Atas permintaan dari Entitas Pemasok Xiaomi yang terkait kapan pun,
            Pengguna harus (i) mengembalikan kepada Entitas Pemasok Xiaomi
            dan/atau Afiliasinya semua Informasi Rahasia, semua dokumen atau
            media yang berisi Informasi Rahasia tersebut, dan semua salinan atau
            ekstraknya, atau (ii) memusnahkan semua dokumen atau media yang
            berisi Informasi Rahasia tersebut, dan semua salinan atau
            ekstraknya, serta memberikan kepada Entitas Pemasok Xiaomi
            sertifikasi tertulis atas pemusnahan tersebut yang ditandatangani
            oleh perwakilan resmi Pengguna.
          </li>
          <li>
            Pengguna bertanggung jawab atas kebocoran Informasi Rahasia oleh
            personilnya.
          </li>
          <li>
            Pengguna juga harus mematuhi perjanjian kerahasiaan yang
            ditandatangani dengan Entitas Pemasok Xiaomi terkait. Jika klausul
            Kerahasiaan ini bertentangan dengan ketentuan perjanjian kerahasiaan
            yang disebutkan di atas, maka ketentuan yang lebih ketat yang akan
            berlaku.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Keadaan Kahar</span>
        <ol>
          <li>
            Pengguna dan Entitas Pemasok Xiaomi terkait tidak bertanggung jawab
            atas kegagalan atau keterlambatan pemenuhan kewajibannya, karena
            keadaan di luar kendali wajarnya akibat peristiwa keadaan kahar
            atau, dalam hal apa pun, kejadian yang tidak terduga dan tidak dapat
            diprediksi dan dalam hal apa pun, tidak bergantung pada
            keinginannya.
          </li>
          <li>
            Pemenuhan kewajiban oleh Pengguna atau Entitas Pemasok Xiaomi
            terkait akan dianggap ditangguhkan selama periode terjadinya
            peristiwa keadaan kahar.
          </li>
          <li>
            Pengguna dan Entitas Pemasok Xiaomi terkait akan melakukan tindakan
            apa pun dengan kekuatannya untuk mengidentifikasi solusi yang
            memungkinkan pemenuhan kewajibannya dengan benar meskipun ada
            peristiwa yang terus berlanjut karena keadaan kahar.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Jangka Waktu dan Pengakhiran</span>
        <ol>
          <li>
            S&K berlaku efektif pada saat pertama kali Pengguna menyetujui S&K
            atau saat pertama kali menggunakan Fitur, mana saja yang lebih awal,
            dan tetap berlaku selama Pengguna menggunakan Platform Xiaomi
            Channel dan Fitur, hingga diakhiri lebih awal sesuai dengan hukum
            yang berlaku dan S&K.
          </li>
          <li>
            Entitas Pemasok Xiaomi dapat mengakhiri S&K dan membatalkan Akun
            Pengguna setiap saat dengan alasan apa pun tanpa pemberitahuan, jika
            terjadi pelanggaran terhadap S&K dan/atau jika terjadi perilaku yang
            melanggar hukum dalam menggunakan Platform Xiaomi Channel dan Fitur
            sesuai dengan hukum yang berlaku termasuk hukum dimana Pengguna dan
            Entitas Pemasok Xiaomi berada.
          </li>
          <div
            className={`${styles["mt-8"]} ${styles["mb-8"]} ${styles["indentation-w"]}`}
          >
            {fonSizeBold(
              "ENTITAS PEMASOK XIAOMI JUGA MEMILIKI HAK INI MESKIPUN JIKA HANYA TERDAPAT DUGAAN PENGGUNA MELANGGAR KETENTUAN DI ATAS. "
            )}
          </div>
          <li>
            Tidak tunduk pada ketentuan sebelumnya, Entitas Pemasok Xiaomi
            berhak untuk mengakhiri S&K jika Pengguna melanggar hak kekayaan
            intelektual pihak ketiga atau menerima pemberitahuan dari pemilik
            atau perwakilan hukum pemilik hak kekayaan intelektual tersebut.
          </li>
          <li>
            Setelah S&K diakhiri, semua Akun dan hak Pengguna untuk menggunakan
            Platform Xiaomi Channel dan Fitur juga akan diakhiri. Pengguna harus
            memahami bahwa hal ini berarti konten pada Platform Xiaomi Channel
            yang terkait dengan Pengguna dapat dihapus dari basis data Entitas
            Pemasok Xiaomi atau Afiliasinya, tanpa mengurangi kewajiban hukum
            apa pun dalam hal ini. Entitas Pemasok Xiaomi tidak akan bertanggung
            jawab atas pengakhiran S&K, termasuk pengakhiran Akun dan
            penghapusan konten di Platform Xiaomi Channel.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Pihak Ketiga</span>
        <ol>
          <li>
            Pengguna memahami dan menyetujui bahwa Platform Xiaomi Channel dan
            Fitur-fiturnya mungkin memerlukan dukungan teknis dari pihak ketiga.
            Pengguna memahami dan menyetujui bahwa Entitas Pemasok Xiaomi dan
            Afiliasinya dapat memberikan beberapa data pribadi Pengguna kepada
            pihak ketiga tersebut dalam rangka menerima dukungan teknis atau
            dukungan lainnya dari mereka. Pengguna setuju dan mengizinkan bahwa
            beberapa batasan mungkin diminta untuk haknya sesuai dengan
            layanan/perangkat lunak pihak ketiga.
          </li>
          <li>
            Pengguna memahami dan menyetujui bahwa Platform Xiaomi Channel dapat
            berisi tautan ke situs web atau aplikasi pihak ketiga. Entitas
            Pemasok Xiaomi tidak memiliki kendali atas mereka dan, oleh karena
            itu, sama sekali tidak bertanggung jawab atas konten situs/aplikasi
            tersebut.
          </li>
          <li>
            Ketika Pengguna mengunjungi aplikasi, situts web dan iklan dari
            pihak ketiga, syarat dan kebijakan pihak ketiga tersebut akan
            berlaku. Pengguna akan menanggung semua risiko dan tanggung jawab
            hukum ketika menggunakan layanan pihak ketiga.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Hak Kekayaan Intelektual dan Industri</span>
        <ol>
          <li>
            Entitas Pemasok Xiaomi dan Afiliasinya menyatakan diri sebagai
            pemegang lisensi atau pemilik semua hak kekayaan intelektual yang
            diperlukan terkait dengan Platform Xiaomi Channel, Fitur dan/atau
            konten yang tersedia di Platform Xiaomi Channel. Semua rahasia
            dagang, merek dagang, kiasan atau kata dan semua tanda, nama
            komersial, ilustrasi, gambar, logo, konten yang berkaitan dengan
            Platform Xiaomi Channel, Fitur dan/atau konten yang tersedia di
            Platform Xiaomi Channel dilindungi oleh hukum yang berlaku atas
            rahasia dagang, merek dagang, hak cipta, hak pengarang, dan
            lain-lain dan oleh perjanjian internasional yang terkait.
          </li>
          <li>
            S&K tidak memberikan lisensi apa pun kepada Pengguna untuk
            penggunaan yang berkaitan dengan Platform Xiaomi Channel dan/atau
            konten dan/atau materi yang tersedia di dalamnya, kecuali jika
            diatur lain.
          </li>
          <li>
            Setiap reproduksi dalam bentuk apa pun dari teks penjelasan dan
            konten Platform Xiaomi Channel dan Fitur-fiturnya, jika tidak
            diizinkan, akan dianggap sebagai pelanggaran terhadap hak kekayaan
            intelektual dan industri dari Entitas Pemasok Xiaomi dan
            Afiliasinya.
          </li>
          <li>
            Pengguna dilarang menjual, menyewakan, mengalihkan, melepaskan, atau
            membuat penggunaan komersial lainnya atas konten yang tersedia di
            Platfom Saluran Xiaomi (termasuk namun tidak terbatas pada konten
            yang mungkin milik pihak ketiga).
          </li>
          <li>
            Pengguna tidak boleh mengakses Platform Xiaomi Channel atau
            menggunakan Fitur dan menyalin kontennya untuk membuat situs web
            atau aplikasi serupa.
          </li>
          <li>
            Kecuali jika secara tegas ditentukan oleh hukum atau S&K, Pengguna
            tidak boleh menyalin, mempublikasikan, mengunduh, mengubah,
            menerjemahkan, menggabungkan, menguraikan, menempelkan,
            mendekompilasi, dan lain-lain bagian mana pun dari Platform Xiaomi
            Channel, Fitur dan isinya dengan cara apa pun.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Pemberitahuan</span>
        <ol>
          <li>
            Setiap pemberitahuan atau komunikasi lain kepada Pengguna sehubungan
            dengan Platform Xiaomi Channel dan Fiturnya harus dilakukan dalam
            Bahasa Inggris dan disampaikan secara tertulis, disampaikan secara
            langsung, dikirimkan melalui pos tercatat atau dikirimkan melalui
            faksimili atau email, dan dialamatkan ke korespondensi, faksimili,
            atau alamat email yang terakhir diketahui, yang diberikan oleh
            Pengguna di Platfom Xiaomi Channel atau ke alamat dan rincian lain
            yang dapat diberitahukan oleh Pengguna kepada Xiaomi secara tertulis
            untuk tujuan tersebut.
          </li>
          <li>
            Setiap pemberitahuan atau komunikasi lain kepada Entitas Pemasok
            Xiaomi sehubungan dengan Platform Xiaomi Channel dan Fitur harus
            dilakukan secara tertulis dan dikirim melalui email ke
            <a href="mailto:robot@xiaomi.com"> robot@xiaomi.com</a> atau ke
            alamat atau rincian lain yang dapat diberitahukan oleh Entitas
            Pemasok Xiaomi kepada Pengguna secara tertulis untuk tujuan
            tersebut.
          </li>
          <li>
            Pengguna setuju bahwa semua perjanjian, pemberitahuan, permintaan,
            pengungkapan, dan komunikasi lain yang dikirimkan oleh Entitas
            Pemasok Xiaomi kepada Pengguna secara elektronik akan memenuhi
            persyaratan hukum apa pun yang mengharuskan komunikasi tersebut
            dibuat secara tertulis.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${getSty("bold-box")}`}>
        <span>Hukum yang Mengatur dan Yurisdiksi</span>
        <ol>
          <li>
            S&K INI TUNDUK PADA HUKUM NEGARA REPUBLIK INDONESIA TANPA
            MEMPERHATIKAN PERTENTANAN PRINSIP-PRINSIP HUKUM, SEJAUH TIDAK
            BERTENTANGAN DENGAN PERATURAN WAJIB APA PUN YANG BERLAKU DI WILAYAH
            TEMPAT PENGGUNA DAN/ATAU ENTITAS PEMASOK XIAOMI TERKAIT BERADA DAN
            DENGAN SYARAT DAN KETENTUAN PEMBERIAN LISENSI DARI PIHAK KETIGA
            KEPADA AFILIASI ENTITAS PEMASIK XIAOMI TERKAIT DENGAN PENGGUNAAN
            PLATFORM XIAOMI CHANNEL.
          </li>
          <li>
            SETIAP PERSELISIHAN, KONTROVERSI, PERBEDAAN ATAU KLAIM YANG TIMBUL
            DARI, SEHUBUNGAN DENGAN S&K, TERMASUK KEBERADAAN, KEABSAHAN,
            PENERAPAN, PELAKSANAAN, PELANGGARAN ATAU PENGAKHIRAN DARIPADANYA
            ATAU PERSELISIHAN APA PUN TERKAIT KEWAJIBAN NON-KONTRAKTUAL YANG
            TIMBUL DARI ATAU TERKAIT DENGAN S&K HARUS DIRUJUK DAN AKHIRNYA
            DISELESAIKAN OLEH ARBITRASE YANG DISELENGGARAKAN OLEH BADAN
            ARBITRASE NASIONAL INDONESIA (BANI) DI BAWAH PERATURAN ARBITRASE
            BANI YANG BERLAKU SAAT PEMBERITAHUAN ARBITRASE DIAJUKAN.
          </li>
          <div className={`${styles["mt-8"]} ${styles["indentation-w"]}`}>
            SENGKETA AKAN DIADILI OLEH SEORANG ARBITER TUNGGAL, YANG HARUS
            DISETUJUI OLEH PENGGUNA DAN ENTITAS PEMASOK XIAOMI TERKAIT ATAU JIKA
            KESEPAKATAN TIDAK TERCAPAI OLEH PARA PIHAK DALAM WAKTU 1 (SATU)
            BULAN SETELAH PEMBERITAHUAN ARBITRASE, MAKA ARBITER AKAN DITUNJUK
            OLEH KETUA BANI. ARBITRASE AKAN DILAKUKAN DALAM BAHASA INDONESIA.
            PUTUSAN BANI BERSIFAT FINAL DAN MENGIKAT. TIDAK ADA DALAM PERJANJIAN
            INI YANG DAPAT MENGHALANGI PENGGUNA ATAU ENTITAS PEMASOK XIAOMI
            TERKAIT UNTUK MEMINTA PUTUSAN SELA ATAU PUTUSAN SERUPA DARI
            PENGADILAN DENGAN YURISDIKSI YANG KOMPETEN JIKA DIPERLUKAN UNTUK
            MENEGAKKAN HAK-HAKNYA BERDASARKAN S&K INI, DAN UNTUK TUJUAN INI,
            MASING-MASING PIHAK SECARA TIDAK DAPAT DITARIK KEMBALI MENYETUJUI
            BAHWA PENGADILAN NEGERI JAKARTA SELATAN MEMILIKI YURISDIKSI
            EKSKLUSIF UNTUK MENYELESAIKAN SENGKETA ATAU KLAIM TERSEBUT.
          </div>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Ketentuan Umum </span>
        <ol>
          <li>
            Jika ada ketentuan dalam S&K yang dianggap batal, tidak sah atau
            tidak dapat diberlakukan dalam hal apa pun, keabsahan, legalitas,
            dan keberlakukan ketentuan-ketentuan lainnya tidak akan terpengaruh
            atau terganggu karenanya.
          </li>
          <li>
            Entitas Pemasok Xiaomi berhak untuk mengalihkan atau mentransfer ke
            salah satu dari mitranya dan Pengguna, Afiliasi, atau anggota grup
            Xiaomi, sebagian atau semua hak dan kewajibannya berdasarkan S&K
            ini, kapan saja dan tanpa pemberitahuan sebelumnya kepada Pengguna.
            Namun, jika terjadi perubahan tersebut, hak Pengguna terkait
            S&Ktidak akan terpengaruh dengan cara apa pun. Pengguna tidak dapat
            mengalihkan hak dan kewajibannya dalam S&K ini tanpa persetujuan
            tertulis sebelumnya dari Entitas Pemasok Xiaomi yang bersangkutan.
            Perilaku atau aktivitas apa pun yang melanggar klausul ini adalah
            tidak sah.
          </li>
          <li>
            S&K termasuk perjanjian tambahan dan dokumen lain yang dirujuk di
            sini, merupakan perjanjian utama, integral dan eksklusif antara
            Pengguna dan Entitas Pemasok Xiaomi sehubungan dengan penggunaan
            Platform Xiaomi Channel dan Fitur lainnya.
          </li>
          <li>
            Judul setiap paragraph ditulis hanya untuk kenyamanan membaca dan
            tidak memiliki kewajiban hukum atau kontrak apa pun.
          </li>
          <li>
            Tidak ada hubungan keagenan, kemitraan, usaha patungan,
            karyawan-majikan, atau pewaralaba-penerima waralaba yang dimaksudkan
            atau dibuat oleh S&K dan perjanjian tambahannya.
          </li>
          <li>
            Kegagalan apa pun oleh Entitas Pemasok Xiaomi dan Afiliasinya untuk
            menggunakan hak apa pun berdasarkan S&K bukan merupakan
            pengesampingan hak tersebut atau pengesampingan sehubungan dengan
            pelanggaran berikutnya atau pelanggaran serupa. Pengesampingan hanya
            akan efektif jika dibuat secara tertulis.
          </li>
          <li>
            Jika Entitas Pemasok Xiaomi menerjemahkan dan menyebarkan S&K dalam
            Bahasa lain, Pengguna setuju bahwa terjemahan tersebut disediakan
            hanya untuk kemudahan dan bahwa versi Bahasa Inggris dari S&K akan
            mengatur penggunaan Platform Xiaomi Channel, kecuali sejauh yang
            dilarang oleh hukum yang berlaku.
          </li>
        </ol>
      </div>
      <footer>Latest release: August 20, 2024</footer>
    </div>
  );
}
