import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
// import success from '../../assets/toastIcons/success-icon.png'
import error from '../../assets/toastIcons/error-icon.png'
// import info from '../../assets/toastIcons/info-icon.png'
// import loading from '../../assets/toastIcons/loading-icon.gif'
import styles from './index.less'

let timer:any = null
// let _scrollTop = 0
export default class Toast extends React.Component{    
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: any){
        super(props)
    }
    // static success(mes='success', place='bottom', duration = 2500){
    //     init()
    //     setTime(duration)
    //     let boxStyle = getBoxPlace(place);
    //     ReactDOM.render(
    //         <Fragment>
    //             <div className={styles['toast']}>
    //                 <div className={styles['toast-box']} style={boxStyle}>
    //                     <img className={styles['toast-icon']} src={success} alt=""/>
    //                     <span className={styles['toast-text']}>{mes}</span>
    //                 </div>
    //             </div>
    //         </Fragment>,
    //         document.getElementById('toast')
    //     )
    // }
    static error(mes='fail', place='bottom', duration = 2500){
        init()
        setTime(duration)
        let boxStyle = getBoxPlace(place);
        ReactDOM.render(
            <Fragment>
                <div className={styles['toast']}>
                    <div className={styles['toast-box']} style={boxStyle}>
                        <img className={styles['toast-icon']} src={error} alt=""/>
                        <span className={styles['toast-text']}>{mes}</span>
                    </div>
                </div>
            </Fragment>,
            document.getElementById('toast')
        )
    }
    // static info(mes='', place='bottom', duration = 2500){
    //     init()
    //     setTime(duration)
    //     let boxStyle = getBoxPlace(place);
    //     ReactDOM.render(
    //         <Fragment>
    //             <div className={styles['toast']}>
    //                 <div className={styles['toast-box']} style={boxStyle}>
    //                     <img className={styles['toast-icon']} src={info} alt=""/>
    //                     <span className={styles['toast-text']}>{mes}</span>
    //                 </div>
    //             </div>
    //         </Fragment>,
    //         document.getElementById('toast')
    //     )
    // }
    // static loading(status: any, place='bottom', mes=''){
    //     init()
    //     setLoading(status)
    //     let boxStyle = getBoxPlace(place);
    //     ReactDOM.render(
    //         <Fragment>
    //             <div className={styles['toast']}>
    //                 <div className={styles['toast-box']} style={boxStyle}>
    //                     <img className={styles['toast-icon']} src={loading} alt=""/>
    //                     <span className={styles['toast-text']}>{mes}</span>
    //                 </div>
    //             </div>
    //         </Fragment>,
    //         document.getElementById('toast')
    //     )
    // }
}
function getBoxPlace(place: string) {
    let boxStyle = {};
    switch (place) {
        case 'top': boxStyle = { top: '120px' }; break;
        case 'bottom': boxStyle = { bottom: '120px' }; break;
        case 'center': boxStyle = { top: '50%', transform: 'translate(-50%, -50%)' }; break;
        default: break;
    }
    return boxStyle;
}

// //弹层出现时设置body类名，防止页面滚动
// function popupOpen() {
//     const ele = document.scrollingElement;
//     if (ele) {
//         _scrollTop = ele.scrollTop
//         document.body.classList.add('body-popup')
//         document.body.style.top = - _scrollTop + 'px'
//     }
// }
// //弹层隐藏时恢复滚动
// function popupClose() {
//     const ele = document.scrollingElement;
//     if (ele) {
//         document.body.classList.remove('body-popup')
//         ele.scrollTop = _scrollTop
//     }
// }
// //设置loading
// function setLoading(status: any){
//     let toast = document.getElementById('toast')
//     if (!toast) {
//         return;
//     }
//     if(status){
//         toast.style.display = 'block'
//         popupOpen()
//     }else{
//         toast.style.display = 'none'
//         popupClose()
//     }
// }
//初始化toast
function init(){
    clearTimeout(timer)
    let toast = document.getElementById('toast')
    if(toast){
        toast.style.display = 'block'
    }else{ 
       let div = document.createElement('div')
        div.setAttribute('id', 'toast')
        document.body.appendChild(div)
    }
}
//设置定时器
function setTime(duration:any){
    timer = setTimeout(()=>{
        let toast = document.getElementById('toast')
        if(toast){
            toast.style.display = 'none'
        }
    }, duration)
}
