/*
 * @Author: liuhuazhou liuhuazhou@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: liuhuazhou liuhuazhou@xiaomi.com
 * @LastEditTime: 2024-08-19 11:37:45
 * @FilePath: /RetailApp/retail-global-h5/src/pages/Home/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'rmc-tabs/assets/index.css'
import HomePage from './Home/HomePage';
// 零售通国际
import DownloadsPage from './RetailGlobal/Downloads';
import RetailGlobalPrivacyNoticeENUS from './RetailGlobal/Protocol/PrivacyNotice/en-US';
import RetailGlobalPrivacyNoticeIDID from './RetailGlobal/Protocol/PrivacyNotice/id-ID';
import RetailGlobalUserAgreementENUS from './RetailGlobal/Protocol/UserAgreement/en-US';
import RetailGlobalUserAgreementIDID from './RetailGlobal/Protocol/UserAgreement/id-ID';

// Channel 分销通
import ChannelPage from './Channel/Downloads';
import ProtocolENUS from './Channel/protocol/en-US';
import ProtocolZHCN from './Channel/protocol/zh-CN';
import ProtocolID from './Channel/protocol/id-ID';


import './global.less';
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://b2d8a844f5e14b79ad519644c553d6fc@sentry.d.xiaomi.net/1439",
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [],
});

function App() {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/RetailGlobal/Downloads' element={<DownloadsPage />} />
      <Route path='/RetailGlobal/Protocol/PrivacyNotice' element={<RetailGlobalPrivacyNoticeENUS />} />
      <Route path='/RetailGlobal/Protocol/PrivacyNotice/en-US' element={<RetailGlobalPrivacyNoticeENUS />} />
      <Route path='/RetailGlobal/Protocol/PrivacyNotice/id-ID' element={<RetailGlobalPrivacyNoticeIDID />} />
      <Route path='/RetailGlobal/Protocol/UserAgreement' element={<RetailGlobalUserAgreementENUS />} />
      <Route path='/RetailGlobal/Protocol/UserAgreement/en-US' element={<RetailGlobalUserAgreementENUS />} />
      <Route path='/RetailGlobal/Protocol/UserAgreement/id-ID' element={<RetailGlobalUserAgreementIDID />} />
      <Route path="/ChannelPage/Downloads" element={<ChannelPage />} />
      <Route path="/ChannelPage/protocol/en-US" element={<ProtocolENUS />} />
      <Route path="/ChannelPage/protocol/zh-CN" element={<ProtocolZHCN />} />
      <Route path="/ChannelPage/protocol/id-ID" element={<ProtocolID />} />
    </Routes>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
const rootDom = document.getElementById('root')
if (rootDom) {
  rootDom.style.height = '100%';
  rootDom.style.maxWidth = '540px';
  rootDom.style.flex = '1';
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
