import React, { useEffect } from "react";
import globalStyles from "../../../global.less";
import styles from "./index.less";
import { setTitleDesc } from '../../../../utils/tools'

const getSty = (str: string) => styles[str];
const fonSizeBold = (str: string, cla?: string) => (
  <span className={`${getSty("KeyPoint")} ${cla ? styles[cla] : ""}`}>
    {str}
  </span>
);

export default function ProtocolZHCN() {
  useEffect(() => {
    setTitleDesc('Xiaomi Channel', '小米分销通')
  }, [])
  
  return (
    <div className={`${globalStyles["container"]} ${getSty("protocol-zh-cn")}`}>
      <h2 className={getSty("title-h2")}>Xiaomi Channel_T&C</h2>
      <p className={getSty("explain-title")}>Xiaomi Channel Platform</p>
      <p className={getSty("explain-title")}>Terms and Conditions </p>
      <h5 className={getSty("explain")}>
        PLEASE READ THE TERMS AND CONDITIONS CAREFULLY AND PAY ATTENTION TO
        PROVISIONS WHICH APPEAR IN BOLD AND CAPITAL LETTERS.
      </h5>
      <p className={getSty("explain")}>
        This document contains the general terms and conditions on the basis of
        which Buyer concludes transactions, consults and deals with transaction
        information with Seller by using Xiaomi Channel Platform and its
        Features owned, operated, branded and made available by Xiaomi Supply
        Entity (defined below).
      </p>
      <p className={getSty("explain")}>
        The Terms and Conditions are entered into between User and Xiaomi Supply
        Entity.​
      </p>
      <div className={getSty("item-box")}>
        <span>Definitions​</span>
        <p className={getSty("title-explain")}>
          To allow a complete understanding and acceptance of the terms and
          conditions contained herein, the following terms, in the singular and
          in the plural, shall have the meaning indicated below:
        </p>
        <ol>
          <li>
            {fonSizeBold("Account")} has the meaning as set out in clause
            “Registration and Login to Xiaomi Channel Platform”.
          </li>
          <li>
            {fonSizeBold("Affiliate")} means, with respect to a Xiaomi Supply
            Entity, any other person which, directly or indirectly, owns or
            Controls, or is owned or Controlled by, or is under common Control
            with this Xiaomi Supply Entity. Some of the Features may be
            supported by Affiliates of Xiaomi Supply Entities.
          </li>
          <li>
            {fonSizeBold("Confidential Information")} means (1) any information
            or data created, posted, uploaded, or communicated through Xiaomi
            Channel Platform; and (2) any information or data in relation to the
            existence, design, layout, building, feature, content, etc. of
            Xiaomi Channel Platform, including the existence and the terms of
            the T&C.
          </li>
          <li>
            {fonSizeBold("Content uploaded by Xiaomi")} has the meaning as set
            out in the clause “Content Uploaded by Xiaomi Supply Entity”.
          </li>
          <li>
            {fonSizeBold("Control")} means possession, directly or indirectly,
            of the power to direct or cause the direction of the management and
            policies of an entity, whether through ownership of voting
            securities, by contract interest or otherwise.
          </li>
          <li>
            {fonSizeBold("Equipment")} means any product manufactured and/or
            distributed by Xiaomi Supply Entity and/or its direct and indirect
            Affiliates and/or by any other member in Xiaomi group, bearing the
            brand, logo, label, sign, or signage of Mi, Xiaomi, Redmi, etc.
          </li>
          <li>
            {fonSizeBold("Features")} means the features enumerated in the
            clause “Features”.
          </li>
          <li>
            {fonSizeBold("Law")} means any and all statutes, laws, ordinances,
            regulations, rules, codes, constitutions, treaties, common laws,
            governmental order or other requirement or rule of law of any
            governmental authority.
          </li>
          <li>
            {fonSizeBold("Buyer")} means the clients who purchase Equipment on
            Xiaomi Channel Platform, normally includes distributors and
            independent retailers. Since the login and access to Xiaomi Channel
            Platform are made on behalf of Buyer by their appointed and
            authorized employees, any reference to “Buyer” in the T&C is
            therefore to be understood also with regard to their employees.
          </li>
          <li>
            {fonSizeBold("Personnel")} means employees, representatives, agents,
            subcontractors or any other third parties engaged or appointed by
            the User including Buyer or Seller.
          </li>
          <li>
            {fonSizeBold("Seller")} means a seller which uses Xiaomi Channel
            Platform to sell Equipment to Buyers, and includes a Third Party
            Vendor, normally distributor in the case that the Buyer is a
            retailer. Xiaomi Local Entity may also be a Seller.
          </li>
          <li>
            {fonSizeBold("Third Party Vendor")} means a seller which uses Xiaomi
            Channel Platform to sell Equipments to the Buyers, and excludes
            Xiaomi Supply Entity.
          </li>
          <li>
            {fonSizeBold("Terms and Conditions (“T&C”)")} means this contract
            regulating the relationship between User and Xiaomi Supply Entity.
          </li>
          <li>
            {fonSizeBold("User")} means who uses the features of Xiaomi Channel
            Platform to buy and sell equipment, including the Buyer and Seller.
          </li>
          <li>
            {fonSizeBold("Xiaomi Channel Platform ")} means the website, mobile
            site and mobile application of Xiaomi Channel Platform (referred to
            as the Platform), through which Buyer can access and use the
            Features.
          </li>
          <li>
            {fonSizeBold("Xiaomi Supply Entity")} means any entity in Xiaomi
            group that might supply Equipment.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Features</span>
        <p className={getSty("title-explain")}>
          Xiaomi Channel Platform has features facilitating Buyer’ Equipment
          purchase activities, which include but not limited to,
        </p>
        <ol>
          <li>
            {fonSizeBold("Buyer’s Profile")}
            <p>
              By using this feature, Buyer can (1) consult on the “Account”
              section for basic information concerning it, such as business
              registration information, tax information, shareholder
              information, delivery address information, bank account
              information and the relevant attachments; and (2) consult on
              “Account” section the agreement/contract executed between Buyer
              and its corresponding Xiaomi Supply Entity.
            </p>
          </li>
          <li>
            {fonSizeBold("Finance Information Viewing ")}
            <p className={`${styles["mb-8"]} ${styles["mt-8"]}`}>
              By using this feature, Buyer can view the credit notes used by
              Buyer.
            </p>
            <p className={`${styles["mb-8"]} ${styles["mt-8"]}`}>
              {fonSizeBold(
                "FOR AVOIDANCE OF DOUBT, THE DATA AND INFORMATION DISPLAYED IN THIS SECTION ARE ONLY USED AS REFERENCE FOR RECONCILIATION, IF BUYER HAS ANY QUESTIONS ABOUT THE DATA AND INFORMATION DISPLAYED, THEY CAN CONTACT THE SALES MANAGER DESIGNATED BY ITS CORRESPONDING SELLER. "
              )}
            </p>
          </li>
          <li>
            {fonSizeBold("Purchase Order Management")} means (1) any information
            <p>
              Through this feature, Buyer can (1) check the situation of
              previous purchase orders placed within three (03) months; (2)
              check the delivery notes for the purchase orders placed within
              three (03) months; and (3) place purchase orders to its
              corresponding Seller, to purchase Equipment by completing and
              submitting online purchase orders with the form displayed on
              Xiaomi Channel Platform.
            </p>
            <p>
              When placing a purchase order, Buyer should fill in all the
              information needed. The purchase order is sent when Buyer submits
              it to its corresponding Seller and is received by when it is
              received by the first employee of Seller who is in charge of
              Equipment sales.
            </p>
            <p>
              If the purchase order is rejected by Seller, a written notice will
              be sent to Buyer or Buyer will be contacted by the sales manager
              designated by its corresponding Seller.
            </p>
            <p>
              Where the Buyer has placed an order on Xiaomi Channel Platform for
              the purchase of Equipments sold by Xiaomi Local Entity and Xiaomi
              Local Entity has accepted the same, this shall constitute a
              contractual relationship entered into directly between the Buyer
              and Xiaomi Local Entity.
            </p>
            <p>
              On the other hand, where the Buyer has placed an order on Xiaomi
              Channel Platform for the purchase of Equipments sold by a
              Third-Party Vendor and the said Third-Party Vendor has accepted
              the same, this shall constitute a contractual relationship entered
              into directly between the Buyer and the Third-Party Vendor. Where
              the contract is entered into directly between the Buyer and a
              Third-Party Vendor, Xiaomi Local Entity is not a party to the
              contract or any other agreements between the Buyer and Third-Party
              Vendor and accepts no obligations in connection with any such
              agreements. Parties to such contracts or any other agreements
              shall be entirely responsible for the agreement between them, the
              listing of Equipments, warranty of purchase and the like.
            </p>
            <p>
              A purchase order can be revoked by Buyer before it is accepted by
              Seller. In case a purchase order is partially performed or not
              performed at all, either Buyer or Seller can initiate a purchase
              order termination procedure.
              {fonSizeBold(
                "IF SELLER INITIATES A TERMINATION PROCEDURE, AN EMAIL WILL BE SENT TO BUYER, BUYER CAN ACCEPT OR REFUSE TO TERMINATE A PURCHASE ORDER, IF BUYER DOES NOT RESPOND TO THE EMAIL WITHIN THREE (03) WORKING DAYS, THE RELEVANT PURCHASE ORDER WILL BE DEEMED TERMINATED BY BOTH PARTIES. "
              )}
            </p>
            <p>
              If Buyer’s information change affects the performance of a
              purchase order, Xiaomi Supply Entity has the right to suspend for
              a reasonable time the performance of the purchase order or take
              other appropriate measures accordingly without bearing any
              additional costs or liability.
            </p>
          </li>
          <div className={styles["mg-42"]}>
            {fonSizeBold(
              "FOR AVOIDANCE OF DOUBT, BUYER AGREES THAT, THE TERMS AND CONDITIONS CONTAINED IN THE PURCHASE ORDER FORM ON XIAOMI CHANNEL PLATFORM ARE THAT IT DISCUSSED WITH THE CORRESPONDING XIAOMI SUPPLY ENTITY, AND IN CASE IT PLACES PURCHASE ORDERS THROUGH THIS FEATURE, IT ACCEPTS AND WILL BE BOUND BY THE TERMS AND CONDITIONS ON THE PURCHASE ORDERS.  "
            )}
          </div>
          <li>
            {fonSizeBold("Distribution Management")}
            <p>
              Through this feature, Buyer can fill in and manage its
              distribution orders which indicate receipt, sale or return of the
              Equipment.
            </p>
          </li>
          <li>
            {fonSizeBold("Message Receiving")}
            <p>
              Through this feature, Buyer can receive messages from the Seller
              or Xiaomi Supply Entity.
            </p>
          </li>
          <li>
            {fonSizeBold("Other Features")}
            <p>
              Buyer may also check some aggregate business data based on the
              above functions.
            </p>
            <div className={styles["mg-42"]}>
              The access and the use of Xiaomi Channel Platform and the Features
              is free for Buyer who freely decides to access and use Xiaomi
              Channel Platform.
            </div>
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Acceptance of the T&C</span>
        <ol>
          <li>
            Before using the Features, User is required to read the T&C
            carefully, fully understand all of its sections, including the terms
            of
            {fonSizeBold(
              " Disclaimer, Limitation of Liability, User’s liability"
            )}
            , and
            {fonSizeBold(" Governing Law and Jurisdiction")}, and choose to
            accept or not the T&C at User’s sole discretion.
          </li>
          <li>
            By registration, login, usage of the Features or other actions, User
            acknowledges that it has read and fully understood, and agrees to
            accept and be bound by the T&C. Xiaomi Supply Entity may suspend or
            stop providing the Features to User if User does not comply with the
            terms and/or policies on Xiaomi Channel Platform.
          </li>
          <li>
            The {fonSizeBold("Privacy Policy", "underline")} and
            {fonSizeBold(" other binding documents", "underline")} available to
            User on Xiaomi Channel Platform, shall be supplementary agreements
            to the T&C with the same legal applicability and validity. If User
            uses thex Features, it shall be deemed as an implied acceptance to
            the T&C and the above supplementary agreements.
          </li>
          <li>
            The T&C and any of the supplementary agreements, when necessary, may
            be revised from time to time by Xiaomi Supply Entity. By accepting
            the T&C, User agrees to accept all constraints, including accepting
            Xiaomi Supply Entity to reserve the right to modify the T&C and
            their supplementary agreements from time to time, with or without
            additional prior notice to User. User can open Xiaomi Channel
            Platform at any time to check the latest T&C and their supplementary
            agreements which will be effective from the time of their
            publication. If User cannot accept any content that Xiaomi Supply
            Entity changed, User shall, at its sole discretion, stop using the
            Features. By continued use of the Features, User agrees to accept
            and will be bound by the modified terms of the T&C and their
            supplementary agreements.
          </li>
          <li>
            The T&C and its supplementary agreements, the relevant supply and
            purchase agreement and other documents referenced in the T&C form a
            legally binding agreement between User and the corresponding Xiaomi
            Supply Entity in relation to its use of Xiaomi Channel Platform and
            the Features. Should User not accept the T&C, its supplementary
            agreements and/or any other note, legal notice, information
            published or referred to therein, User shall not request Accounts,
            register, login or use Xiaomi Channel Platform or the related
            Features, which will be decided at User’s sole discretion.
          </li>
          <li>
            Xiaomi Supply Entity reserves the right but shall not be obliged to
            change, at its own discretion and from time to time, even after the
            User’s registration, the graphic interface of Xiaomi Channel
            Platform, the Features, the contents available on Xiaomi Channel
            Platform and their organization, as well as any other Feature that
            characterizes the functionality and management of Xiaomi Channel
            Platform, with communicating to User the relative instructions, when
            necessary. Any revised version of Xiaomi Channel Platform,
            introduction of new Features, and updates or other changes of the
            existing Features will be governed by the T&C and its supplementary
            agreements.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Registration and Login to Xiaomi Channel Platform</span>
        <ol>
          <li>
            With the intent to use Xiaomi Channel Platform and its Features,
            User can ask and obtain an account or accounts for Xiaomi Channel
            Platform by sending formal request to its corresponding Xiaomi
            Supply Entity (hereinafter “{fonSizeBold("Account")}”), which
            corresponds/correspond to all or part of the Features and will be
            operated by User’s appointed and authorized employees. To this end,
            User is required to specify the full name of their employees
            authorized to access the Account together with their email and/or
            other information required. Once activated, the Account will be
            provided to User by its corresponding Xiaomi Supply Entity together
            with initial passwords of the same Account. During the first access
            to the Account, initial password needs to be changed by User.
          </li>
          <li>
            In order to login to Xiaomi Channel Platform and take advantage of
            the Features, User and/or User’s authorized employees shall enter
            the login credentials (email address and the chosen password, etc.).
            The Accounts can be used only by User’s appointed and authorized
            employees.
          </li>
          <li>
            User hereby commits and undertakes that it is responsible for the
            safety of its Account information and password and should bear legal
            liability for activities under its Accounts. Any illegal login and
            access in Xiaomi Channel Platform conducted by unauthorized persons
            of User shall be a sole responsibility of User and User shall
            indemnify Xiaomi Supply Entity as well as its Affiliates against any
            loss, damage and compensation to other affected third party(ies) due
            to the occurrence of those access.{" "}
          </li>
          <li>
            User also understands and hereby acknowledges and agrees that Xiaomi
            Supply Entity reserves the faculty to change, update, suspend,
            restrict, terminate or revoke the Account at any time temporarily or
            permanently without prior notice and without any liability for any
            losses or damages arising therefrom.{" "}
          </li>
          <li>
            {fonSizeBold("Privacy Policy")}. Xiaomi Supply Entity may collect
            and process personal data from User, and/or its Personnel, for the
            purposes of communicating about, and developing, the mutual business
            relationship of the parties, complying with legal obligations and
            generally giving effect to the terms of the T&C. Any such personal
            data shall be collected and processed in accordance with the Privacy
            Policy, which can be consulted before obtaining the Accounts, when
            logging in to Xiaomi Channel Platform for the first time and/or at
            the bottom of the home page of Xiaomi Channel Platform. 5. Privacy
            Policy. Xiaomi Supply Entity may collect and process personal data
            from User, and/or its Personnel, for the purposes of communicating
            about, and developing, the mutual business relationship of the
            parties, complying with legal obligations and generally giving
            effect to the terms of the T&C. Any such personal data shall be
            collected and processed in accordance with the Privacy Policy, which
            can be consulted before obtaining the Accounts, when logging in to
            Xiaomi Channel Platform for the first time and/or at the bottom of
            the home page of Xiaomi Channel Platform.
          </li>
          <div className={styles['text-indent-2']}>
            {fonSizeBold('BY ACCEPTING THE PRIVACY POLICY, User CONFIRMS ITS CONSENT TO THE COLLECTION AND PROCESSING OF THE PROVIDED PERSONAL DATA BY XIAOMI SUPPLY ENTITY OR ITS AFFILIATES.')}
          </div>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Account Cancellation and Closure</span>
        <ol>
          <li>
            If User wants to deactivate or cancel the Account, it can send a
            written communication to the contact person designated by its
            corresponding Xiaomi Supply Entity.
          </li>
          <li>
            In case of violation by User of the T&C, its supplementary
            agreements and/or of the applicable legal provisions, Xiaomi Supply
            Entity reserves the right to suspend or close the relevant Account
            at any time and without notice.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span className={styles["line-1-4"]}>
          Contractual Relationships between Buyer and Seller
        </span>
        <ol>
          <li>
            The contractual relationships of purchase and sale between Buyer and
            its corresponding Seller shall be governed by the T&C, its
            supplementary agreements, supply and purchase agreements, and
            purchase orders executed by and between them, which include the
            online purchase orders placed by Buyer and agreed by its
            corresponding Seller through Xiaomi Channel Platform.
          </li>
          <li>
            Buyer acknowledges and agrees that the online purchase orders placed
            through Xiaomi Channel Platform and approved by its corresponding
            Seller electronically satisfy any legal requirement that such
            agreement, contract and purchase order should be in writing and
            these purchase orders are in full force which will be fully
            performed by Buyer.
          </li>
          <li>
            In case of conflict, documents shall prevail in the following
            descending order: the T&C along with its amendment and supplementary
            agreements, the supply and purchase agreement, authorization letter,
            the purchase orders. This order does not apply when the conflict
            concerns delivery, service and support, and the definition of
            territory, where purchase order provisions as reflected in each
            individual purchase order shall prevail.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span className={styles["line-1-4"]}>
          Content Uploaded by Xiaomi Supply Entity
        </span>
        <ol>
          <li>
            For some sections of Xiaomi Channel Platform, content regarding User
            can be posted and uploaded by Xiaomi Supply Entity according to
            documents provided by User (Content Uploaded by Xiaomi). For the
            Content Uploaded by Xiaomi, such as User registration information,
            User should check, confirm and ensure their authenticity, legality,
            accuracy and validity.
          </li>
          <li>
            In case of any inaccuracy or changes to the Content Uploaded by
            Xiaomi, User should notify the corresponding Xiaomi Supply Entity in
            time, otherwise, User shall bear all risks and losses arising
            therefrom.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>User’s Representations and Warranties</span>
        <ol>
          <li>
            User represents, warrants and agrees that (1) it has full power and
            authority to accept the T&C and its supplementary agreements, to
            grant the license and authorization and to perform the obligations
            hereunder; and (2) its access to and use of Xiaomi Channel Platform
            and the Features will be for business purposes only and in
            accordance with the T&C and its supplementary agreements.
          </li>
          <li>
            User is or will be required to provide information or material about
            its entity or business as part of the registration process on Xiaomi
            Channel Platform for its access to and use of any Features or the
            Account. User represents, warrants and agrees that (1) such
            information and material whether submitted before, during the
            registration process or thereafter throughout the continuation of
            the use of Xiaomi Channel Platform or Features is true, accurate,
            current and complete, and not false, misleading or deceptive; and
            (2) it will maintain and promptly amend all information and material
            to keep it true, accurate, current and complete.
          </li>
          <li>
            User represents, warrants and agrees that it will use Xiaomi Channel
            Platform and the Features in good faith and in compliance with all
            applicable Laws, including Laws where User and Xiaomi Supply
            Entities are based, Microsoft's user agreement and policy and the
            T&C with its supplementary agreements, take all responsibility for
            activities under User’s Account, and will not use Xiaomi Channel
            Platform to defraud
          </li>
          <li>
            User acknowledges that its sharing of its Accounts with other
            persons or allowing multiple users outside of its business entity to
            use the Account may cause irreparable harm to Xiaomi Supply Entity
            and its Affiliates, and represents, warrants and agrees to take good
            care of the registration information and login credentials on Xiaomi
            Channel Platform and are responsible for all the activities under
            its Account.
          </li>
          <li>
            User represents, warrants and agrees that it will not use the
            Accounts and passwords of other Users and not create, upload or post
            any information in the name of others. User agrees to immediately
            notify Xiaomi Supply Entity once User suspects others using its
            passwords or Accounts by emailing{" "}
            <a href="mailto:robot@xiaomi.com">robot@xiaomi.com</a>.
          </li>
          <li>
            User represents, warrants and agrees that it undertakes to hold
            Xiaomi Supply Entity and its Affiliates harmless from any damage,
            indemnity and/or penalty resulting from and/or in any way connected
            to the infringement by User of Xiaomi Channel Platform registration
            rules, login credentials store requirements and use rules or to any
            other actions of User; otherwise, Xiaomi Supply Entity reserves the
            right to suspend the Account and access to Xiaomi Channel Platform
            and User shall fully bear all legal liability. Xiaomi do not accept
            any responsibility or liability for any claims or loss arising from
            User’s actions.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${styles["bold-box"]}`}>
        <span>DISCLAIMER </span>
        <ol>
          <li>
            XIAOMI SUPPLY ENTITY AND ITS AFFILIATES WILL NOT BEAR ANY LEGAL
            LIABILITY UNDER CIRCUMSTANCES STATED BELOW:
          </li>
          <div className={styles["disorderly"]}>
            PROVISION OF User’S INFORMATION REQUESTED BY GOVERNMENT DEPARTMENTS
            OR APPLICABLE LAWS.
          </div>
          <div className={styles["disorderly"]}>
            DISCLOSURE OF PRIVATE INFORMATION CAUSED BY User’S ACTIONS.
          </div>
          <div className={styles["disorderly"]}>
            DISCLOSURE OF FORBIDDEN INFORMATION ACCORDING TO THE APPLICABLE
            LAWS, INCLUDING LAWS WHERE User AND ITS CORRESPONDING XIAOMI SUPPLY
            ENTITY ARE BASED.
          </div>
          <div className={styles["disorderly"]}>
            ANY CIRCUMSTANCES CAUSED BY SUSPENSION, BREAKDOWN AND SO ON THAT
            RESULT FROM THE HACKING, VIRUS INVASION, BLOCKINGS DUE TO ILLEGAL
            AND HARASSING CONTENT, GOVERNMENT CONTROL OR ANY OTHER REASONS
            RELATED TO NETWORK, TECHNIQUE, COMMUNICATION LINE AND INFORMATION
            SECURITY MANAGEMENT.
          </div>
          <div className={styles["disorderly"]}>
            LOSSES TO THE User OR ANY AFFECTED THIRD PARTIES CAUSED BY THIRD
            PARTIES, SUCH AS THE COMMUNICATION LINE BREAKDOWN, TECHNICAL
            PROBLEMS, THE NETWORK AND COMPUTER BREAKDOWN, AND OTHER CASES OF
            FORCE MAJEURE.
          </div>
          <div className={styles["disorderly"]}>
            RISKS COMING FROM THREAT, DEFAMATION, OFFENSIVE OR ILLEGAL
            INFORMATION THAT ARE CAUSED BY ANONYMOUS OR PERCOLATION THROUGH
            Xiaomi Channel Platform.
          </div>
          <div className={styles["disorderly"]}>
            LOSSES, CLAIM, LEGAL SUITS CAUSED BY THE NEGLIGENCE AND/OR DEFAULT
            OF User IN ANY OF THE PROVISIONS UNDER THIS T&C.
          </div>

          <li>
            XIAOMI CHANNEL PLATFORM AND THE FEATURES ARE PROVIDED "AS IS" AND
            "AS AVAILABLE" AND XIAOMI SUPPLY ENTITY AND/OR ITS AFFILIATES DO NOT
            PROVIDE ANY EXPLICIT OR IMPLICIT GUARANTEE IN RELATION TO Xiaomi
            Channel Platform AND THE FEATURES ABOUT THEIR TIMELINESS, SECURITY
            AND ACCURACY EXPRESSLY, IMPLIEDLY OR IN OTHER FORMS, NOR DO XIAOMI
            SUPPLY ENTITY AND/OR ITS AFFILIATES PROVIDE ANY GUARANTEE THAT
            Xiaomi Channel Platform AND THE FEATURES WILL SATISFY THE NEEDS OF
            User OR THAT THEY WILL NEVER BE INTERRUPTED OR ERROR-FREE OR FREE OF
            VIRUSES OR BUGS.
          </li>
          <li>
            XIAOMI SUPPLY ENTITY AND ITS AFFILIATES WILL ENDEAVOR TO ENSURE THAT
            Xiaomi Channel Platform AND ITS FEATURES ARE AVAILABLE CONTINUOUSLY
            24 HOURS A DAY, BUT CANNOT IN ANY WAY BE HELD RESPONSIBLE IF, FOR
            ANY REASON, Xiaomi Channel Platform AND/OR THE FEATURES ARE NOT
            ACCESSIBLE AND/OR OPERATIONAL AT ANY TIME OR FOR ANY PERIOD. ACCESS
            TO Xiaomi Channel Platform AND/OR THE FEATURES MAY BE SUSPENDED
            TEMPORARILY IN THE EVENT OF SYSTEM FAILURE, MAINTENANCE, REPAIRS OR
            FOR REASONS WHOLLY UNRELATED TO WILL OF XIAOMI SUPPLY ENTITY AND ITS
            AFFILIATES OR DUE TO FORCE MAJEURE EVENTS, IN THE CASE OF WHICH
            XIAOMI SUPPLY ENTITY WILL ENDEAVOR TO INFORM User.
          </li>
          <li>
            UNDER NO CIRCUMSTANCES SHALL XIAOMI SUPPLY ENTITY AND/OR ITS
            AFFILIATES BEAR ANY RESPONSIBILITY FOR ANY INDIRECT, CONSEQUENTIAL,
            EXEMPLARY, INCIDENTAL, EXCEPTIONAL OR PUNITIVE DAMAGES, INCLUDING
            PROFIT LOSSES CAUSED BY USING Xiaomi Channel Platform OR THE
            FEATURES. User BEARS ALL THE RISKS FROM USING THE COMPUTER SYSTEM
            AND MOBILE DATABASE THROUGH Xiaomi Channel Platform OR THE FEATURES.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${styles["bold-box"]}`}>
        <span>LIMITATION OF LIABILITY</span>
        <ol>
          <li>
            XIAOMI SUPPLY ENTITY SHALL NOT BE HELD LIABLE TOWARDS User, EXCEPT
            IN THE CASE OF WILLFUL MISCONDUCT OR GROSS NEGLIGENCE, FOR
            DISSERVICES OR MALFUNCTIONS CONNECTED TO THE USE OF THE INTERNET
            OUTSIDE OF ITS OWN CONTROL OR THAT OF ITS SUPPLIERS.
          </li>
          <li>
            FURTHERMORE, XIAOMI SUPPLY ENTITY WILL NOT BE LIABLE FOR DAMAGES,
            LOSSES AND COSTS INCURRED BY User AS A RESULT OF FAILURE TO EXECUTE
            THE T&C FOR REASONS NOT ATTRIBUTABLE TO XIAOMI SUPPLY ENTITY AND ITS
            AFFILIATES.
          </li>
          <li>XIAOMI SUPPLY ENTITY WILL NOT BE HELD LIABLE FOR:</li>
          <div className={styles["disorderly"]}>
            ANY LOSS OF BUSINESS OPPORTUNITIES AND ANY OTHER LOSS, EVEN
            INDIRECT, POSSIBLY SUFFERED BY User THAT IS NOT A DIRECT RESULT OF
            THE BREACH OF T&C BY XIAOMI SUPPLY ENTITY;
          </div>
          <div className={styles["disorderly"]}>
            UNLAWFUL, INCORRECT OR UNSUITABLE USE OF Xiaomi Channel Platform BY
            User OR THIRD PARTIES.
          </div>
          <li>
            IN THE TERM THAT XIAOMI SUPPLY ENTITY IS LIABLE FOR A BREACH OF THE
            T&C, ITS LIABILITY SHALL BE LIMITED TO DAMAGES WHICH XIAOMI SUPPLY
            ENTITY MUST TYPICALLY EXPECT AT THE TIME OF THE CONCLUSION OF THE
            T&C DUE TO THE CIRCUMSTANCES KNOWN AT THE TIME. NOTWITHSTANDING THE
            ABOVE, SUCH DAMAGES SHALL NOT BE HIGHER THAN 100 USD (OR EQUIVALENT
            LOCAL CURRENCY).
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>User’s Liability</span>
        <ol>
          <li>
            User is entirely responsible for any breach of the obligations
            under:
          </li>
          <div className={styles["disorderly"]}>
            The T&C and their supplementary agreements.
          </div>
          <div className={styles["disorderly"]}>
            Any applicable Law in the relevant jurisdictions, including the
            jurisdiction where User is based or from where User is accessing and
            using Xiaomi Channel Platform, or where its corresponding Xiaomi
            Supply Entity is based.
          </div>
          <div className={`${styles["mt-12"]} ${styles["text-indent-2"]}`}>
            {fonSizeBold(
              "FOR THE CONSEQUENCES OF ANY SUCH BREACH, INCLUDING ANY LOSS OR DAMAGE WHICH XIAOMI SUPPLY ENTITY, ITS AFFILIATES OR ANY THIRD-PARTY MAY INCUR OR SUFFER, XIAOMI SUPPLY ENTITY AND ITS AFFILIATES WILL HAVE NO RESPONSIBILITY TO User OR TO ANY THIRD PARTY IN RESPECT OF SUCH BREACH."
            )}
          </div>
          <div
            className={`${styles["mt-12"]} ${styles["mb-8"]} ${styles["text-indent-2"]}`}
          >
            {fonSizeBold(
              "User SHALL HOLD/ENSURE ITS CORRESPONDING XIAOMI SUPPLY ENTITY AND ITS AFFILIATES FREE FROM ANY RESPONSIBILITY OR LIABILITY FOR ANY CLAIMS OR LOSS ARISING FROM User’S ACTIONS."
            )}
          </div>
          <li>
            User shall not use Xiaomi Channel Platform or the Features to engage
            in any behavior or activity stated below:
          </li>
          <div className={styles["disorderly"]}>
            Upload or release viruses, worms, or malware to damage or change
            computer system or data.
          </div>
          <div className={styles["disorderly"]}>
            Collect the information or any data of others, such as email
            address, without authorization and their direct consent and subject
            to applicable Laws.
          </div>
          <div className={styles["disorderly"]}>
            Disable network connection of Xiaomi Channel Platform, cause Xiaomi
            Channel Platform to overload with traffic, interrupt or undermine
            the server and connection in other ways.
          </div>
          <div className={styles["disorderly"]}>
            Attempt to visit Xiaomi Channel Platform, our server or Xiaomi
            Channel Platform connection without authorization.
          </div>
          <div className={styles["disorderly"]}>
            Interrupt or undermine other Users' normal use of the Features;
          </div>
          <div className={styles["disorderly"]}>
            Engage in any unlawful conduct, according to applicable Laws,
            including Laws where User and its corresponding Xiaomi Supply Entity
            are based.
          </div>
          <div
            className={`${styles["mt-12"]} ${styles["mb-12"]}  ${styles["text-indent-2"]}`}
          >
            {fonSizeBold(
              " User SHALL BEAR ANY LIABILITY FOR THE ABOVE, HOLDING XIAOMI SUPPLY ENTITY AND ITS AFFILIATES UNHARMED."
            )}
          </div>
          <li>
            User agrees to use Xiaomi Channel Platform or Features lawfully,
            harmlessly and bear any lawsuit, complaint, loss, damage,
            responsibility, cost and fees (including but not limited to counsel
            fees), third party’s claims included, caused by its use of Xiaomi
            Channel Platform or the Features and its violation of the T&C.
          </li>
          <div
            className={`${styles["mt-8"]} ${styles["mb-8"]} ${styles["text-indent-2"]}`}
          >
            Xiaomi Supply Entity and/or its Affiliates reserve the exclusive
            right to defend and the right to claim for compensation.
          </div>
          <li>
            User will not unilaterally reconcile when User and its corresponding
            Xiaomi Supply Entity jointly file a lawsuit against any third party
            without written consent from Xiaomi Supply Entity. Xiaomi Supply
            Entity shall reasonably notify User such lawsuit or action at law.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Confidentiality</span>
        <ol>
          <li>
            User along with its Personnel shall at all times keep confidential
            and shall not use or disclose any Confidential Information for any
            purpose other than the performance or enforcement of the T&C and/or
            other rights and obligations relating to Xiaomi Channel Platform and
            the Features, unless with the prior written consent of Xiaomi Supply
            Entity or its Affiliates.
          </li>
          <li>
            User shall take strict security measures to protect Confidential
            Information, including but not limited to establish a
            confidentiality system, train all relevant persons who could get
            access to Confidential Information, sign non-disclosure agreements
            with them, and emphasize the confidentiality of Confidential
            Information, security measures and liabilities of breaching such
            agreements.
          </li>
          <li>
            Unless prior written consent by its corresponding Xiaomi Supply
            Entity and/or its Affiliates, User shall not reverse-engineer,
            decompile, or disassemble any Confidential Information (tangible or
            intangible) disclosed to it and shall not remove, overprint, or
            deface any notice of copyright, trademark, logo, legend, or other
            notices of ownership from any originals or copies of Confidential
            Information disclosed by Xiaomi Supply Entity and/or its Affiliates.
          </li>
          <li>
            Upon request by its corresponding Xiaomi Supply Entity at any time,
            User shall (i) return to Xiaomi Supply Entity and/or its Affiliates
            all Confidential Information, all documents or media containing such
            Confidential Information, and any copies or extracts thereof, or
            (ii) destroy all documents or media containing such Confidential
            Information, and any copies or extracts thereof, and provide Xiaomi
            Supply Entity with written certification of such destruction signed
            by an authorized representative of User.
          </li>
          <li>
            User shall be responsible for the leakage of Confidential
            Information by its Personnel.
          </li>
          <li>
            User shall also comply with the non-disclose agreement it executed
            with the corresponding Xiaomi Supply Entity. When this
            Confidentiality clause contradicts the provisions of the aforesaid
            non-disclosure agreement, the stricter provisions shall prevail.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Force Majeure</span>
        <ol>
          <li>
            User and its corresponding Xiaomi Supply Entity shall not be held
            responsible for the failure or late fulfilment of its obligations,
            due to circumstances beyond its reasonable control due to events of
            force majeure or, in any case, to unforeseen and unforeseeable
            events and, in any case, independent of its will.
          </li>
          <li>
            The fulfilment of the obligations by User or its corresponding
            Xiaomi Supply Entity shall be considered suspended for the period in
            which events of force majeure occur.
          </li>
          <li>
            User and its corresponding Xiaomi Supply Entity will perform any act
            in its power in order to identify solutions that allow the correct
            fulfilment of its obligations despite the persistence of events due
            to force majeure.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Term and Termination</span>
        <ol>
          <li>
            The T&C become effective the first time User agrees to the T&C or
            the first time it uses the Features, whichever is earlier, and stay
            valid during User’s use of Xiaomi Channel Platform and the Features,
            until earlier terminated according to applicable Laws and the T&C.
          </li>
          <li>
            Xiaomi Supply Entity may terminate the T&C and cancel User’s Account
            at any time for any reason without notice, in case of any breach of
            the T&C and/or in case of any unlawful behavior in using Xiaomi
            Channel Platform and the Features according to applicable Laws
            including the Laws where User and Xiaomi Supply Entity are based.
          </li>
          <div className={`${styles["mt-8"]} ${styles["mb-8"]}`}>
            {fonSizeBold(
              "XIAOMI SUPPLY ENTITY RESERVES THIS RIGHT ALSO EVEN IF IT ONLY HAS A SUSPECT THAT User VIOLATES THE ABOVE."
            )}
          </div>
          <li>
            Not subject to the preceding provisions, Xiaomi Supply Entity
            reserves the right to terminate the T&C if User infringes
            intellectual property rights of third parties or receives notice
            from the owner or legal representative of the owner.
          </li>
          <li>
            Once the T&C terminated, all Accounts and User’s right to use Xiaomi
            Channel Platform and the Features will also be terminated. User
            should understand this means the content on Xiaomi Channel Platform
            related to User may be removed from the database of Xiaomi Supply
            Entity or its Affiliates, without prejudice to any legal obligations
            in this respect. Xiaomi Supply Entity will not take any
            responsibility for terminating the T&C, including terminating the
            Accounts and removing the content on Xiaomi Channel Platform related
            to User.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Third Party</span>
        <ol>
          <li>
            User understands and agrees that Xiaomi Channel Platform and its
            Features may need technical support from third parties. User
            understands and consents that Xiaomi Supply Entity and its
            Affiliates may provide some of User’s personal data to such third
            parties in the course of receiving technical or any other support
            from them. User agrees and authorizes that some limits might be
            requested to its rights according to third-party services/software.
          </li>
          <li>
            User understands and agrees that Xiaomi Channel Platform may contain
            links to third-party websites or applications. Xiaomi Supply Entity
            has no control over them and, therefore, is in no way responsible
            for the contents of these sites/applications.
          </li>
          <li>
            When User visits the applications, websites and advertisements of a
            third party, the third party’s terms and policy apply. User will
            bear all risks and legal responsibility when it uses third party’s
            services.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Industrial and Intellectual Property Rights</span>
        <ol>
          <li>
            Xiaomi Supply Entity and its Affiliates declare to be the licensee
            or owner of all the necessary intellectual property rights relating
            to Xiaomi Channel Platform, the Features and/or the content
            available on Xiaomi Channel Platform. All the trade secrets,
            trademarks, figurative or word and all other signs, commercial
            names, service marks, word marks, commercial names, illustrations,
            images, logos, contents relating to Xiaomi Channel Platform, the
            Features and/or the content available on Xiaomi Channel Platform are
            protected by the Laws in force on the trade secrets, trademarks,
            copyright, authors’ rights, etc. and by the relative international
            treaties.
          </li>
          <li>
            The T&C do not grant User any license for use relating to Xiaomi
            Channel Platform and/or to contents and/or materials available
            therein, unless otherwise regulated.
          </li>
          <li>
            Any reproductions in any form of the explanatory texts and the
            contents of Xiaomi Channel Platform and any of its Features, if not
            authorized, will be considered violations of the intellectual and
            industrial property rights of Xiaomi Supply Entity and its
            Affiliates.
          </li>
          <li>
            User is prohibited to sell, lease, transfer, release or make other
            commercial use of the content available on Xiaomi Channel Platform
            (including but not limited to the content that might belong to third
            parties’ property).
          </li>
          <li>
            User shall not access Xiaomi Channel Platform or use the Features
            and copy its content to create similar websites or applications.
          </li>
          <li>
            Unless expressly prescribed by Laws or by the T&C, User shall not
            copy, publish, download, change, translate, merge, decompose, paste
            or decompile etc. any part of Xiaomi Channel Platform, its Features
            and its contents in any manner.
          </li>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>Notice</span>
        <ol>
          <li>
            Any notice or other communication to User in connection with Xiaomi
            Channel Platform and the Features shall be in English language and
            in writing, be delivered by hand, sent by registered post or
            transmitted by facsimile or email, and be addressed to the
            last-known correspondence, fax or email address provided by User on
            Xiaomi Channel Platform or to such other address or details as User
            may notify to Xiaomi in writing for such purpose.
          </li>
          <li>
            Any notice or other communication to Xiaomi Supply Entity in
            connection with Xiaomi Channel Platform and the Features shall be
            made in writing and sent by email to{" "}
            <a href="mailto:robot@xiaomi.com">robot@xiaomi.com</a> or to other
            address or details as Xiaomi Supply Entity may notify to User in
            writing for such purpose.
          </li>
          <li>
            User agrees that all agreements, notices, demands, disclosures and
            other communications that Xiaomi Supply Entity sends to User
            electronically will satisfy any legal requirement that such
            communication should be in writing.
          </li>
        </ol>
      </div>
      <div className={`${getSty("item-box")} ${getSty("bold-box")}`}>
        <span>GOVERNING LAW AND JURISDICTION</span>
        <ol>
          <li>
            THE T&C ARE SUBJECT TO THE LAWS OF HONG KONG WITHOUT REGARD TO
            CONFLICT OF LAW PRINCIPLES, TO THE EXTENT THAT IT DOES NOT CONFLICT
            WITH ANY MANDATORY RULES APPLICABLE IN THE TERRITORIES IN WHICH USER
            AND/OR ITS CORRESPONDING XIAOMI SUPPLY ENTITY ARE LOCATED AND WITH
            THE TERMS AND CONDITIONS OF THE LICENSE GRANTED BY THIRD PARTY TO
            XIAOMI SUPPLY ENTITY’S AFFILIATE REGARDING THE USE OF XIAOMI CHANNEL
            PLATFORM.
          </li>
          <li>
            ANY DISPUTE, CONTROVERSY, DIFFERENCE OR CLAIM ARISING OUT OF,
            RELATING TO THE T&C, INCLUDING THE EXISTENCE, VALIDITY, APPLICATION,
            PERFORMANCE, BREACH OR TERMINATION THEREOF OR ANY DISPUTE REGARDING
            NON-CONTRACTUAL OBLIGATIONS ARISING OUT OF OR RELATING TO IT SHALL
            BE REFERRED TO AND FINALLY RESOLVED BY ARBITRATION ADMINISTERED BY
            HONG KONG INTERNATIONAL ARBITRATION CENTRE (HKIAC) UNDER THE
            UNCITRAL ARBITRATION RULES IN FORCE WHEN THE NOTICE OF ARBITRATION
            IS SUBMITTED, AS MODIFIED BY THE HKIAC PROCEDURES FOR THE
            ADMINISTRATION OF INTERNATIONAL ARBITRATION.
          </li>
          <div className={styles["mt-8"]}>
            THE DISPUTE SHALL BE HEARD BY A SINGLE ARBITRATOR, WHO SHALL BE
            AGREED BY USER AND THE CORRESPONDING XIAOMI SUPPLY ENTITY OR IF
            AGREEMENT IS NOT REACHED BY THE PARTIES WITHIN ONE MONTH OF SERVICE
            OF THE ARBITRATION NOTICE SHALL BE APPOINTED BY THE CHAIRMAN OF
            HKIAC.
          </div>
          <div className={styles["mt-8"]}>
            THE ARBITRATION SHALL BE CONDUCTED IN ENGLISH AND IN HONG KONG.
          </div>
          <div className={styles["mt-8"]}>
            NOTHING IN THIS AGREEMENT SHALL PREVENT USER OR THE CORRESPONDING
            XIAOMI SUPPLY ENTITY FROM SEEKING URGENT INJUNCTIVE OR SIMILAR
            RELIEF FROM ANY COURT OF COMPETENT JURISDICTION IF NEEDED TO ENFORCE
            ITS RIGHTS UNDER THE T&C AND TO THIS END EACH OF THE PARTIES
            IRREVOCABLY AGREES THAT THE COURTS OF HONG KONG HAVE EXCLUSIVE
            JURISDICTION TO SETTLE ANY SUCH DISPUTE OR CLAIM.{" "}
          </div>
        </ol>
      </div>
      <div className={getSty("item-box")}>
        <span>General Provisions</span>
        <ol>
          <li>
            If any provision of the T&C is rendered void, illegal or
            unenforceable in any respect, the validity, legality and
            enforceability of the remaining provisions shall not in any way be
            affected or impaired thereby.
          </li>
          <li>
            Xiaomi Supply Entity reserves the right to assign or transfer to any
            of its associates and Users, Affiliates or members of the Xiaomi
            group, any or all of its rights and obligations under the present
            T&C, at any time and without prior notice to User. However, in the
            event of such change, the User’s rights in regard to the T&C will
            not be adversely affected in any way. User may not assign its rights
            and obligations in this T&C without prior written consent from its
            corresponding Xiaomi Supply Entity. Any behaviour or activity in
            violation of this clause is invalid.
          </li>
          <li>
            The T&C, including its supplementary agreements and other documents
            referenced herein, constitute the ultimate, integral and exclusive
            agreement between User and Xiaomi Supply Entity in relation to the
            use of Xiaomi Channel Platform and the Features.
          </li>
          <li>
            The title of each paragraph is written only for the convenience of
            reading and does not have any legal or contractual obligations.{" "}
          </li>
          <li>
            No agency, partnership, joint venture, employee-employer or
            franchiser-franchisee relationship is intended or created by the T&C
            and its supplementary agreements.{" "}
          </li>
          <li>
            Any failure by Xiaomi Supply Entity and its Affiliates to exercise
            any rights under the T&C shall not constitute a waiver of such right
            or a waiver with respect to subsequent or similar breaches. A waiver
            shall be effective only if made in writing.{" "}
          </li>
          <li>
            If Xiaomi Supply Entity translates and posts the T&C in other
            languages, the User agrees that the translation is provided for
            convenience only and that the English language version of the T&C
            will govern its use of Xiaomi Channel Platform, except to the extent
            prohibited by applicable Law.
          </li>
        </ol>
      </div>
      <footer>Latest release: August 20, 2024</footer>
    </div>
  );
}
