export const setTitleDesc = (title, description) => {
  document.title = title
  // 检查是否已存在description meta标签
  let meta = document.querySelector('meta[name="description"]');
  
  if (meta) {
    // 如果存在，直接修改content
    meta.setAttribute('content', description);
  } else {
    // 如果不存在，创建新的meta标签
    meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = description;
    document.head.appendChild(meta);
  }
};
