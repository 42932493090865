/*
 * @Author: liuhuazhou liuhuazhou@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: liuhuazhou liuhuazhou@xiaomi.com
 * @LastEditTime: 2024-07-22 10:13:04
 * @FilePath: /RetailApp/retail-global-h5/src/pages/RetailGlobal/Downloads/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useState, useEffect } from 'react';
import { Link /*, useNavigate */ } from 'react-router-dom';
import globalStyles from '../../global.less';
import styles from './index.less';
import backgroundImg from './assets/phone_bg_v2.png';
import footerImg from './assets/footer_v2.png';
import { API_RetailDownloadsUrl } from '../../../api/url';
import nextWork from '../../../utils/net';
import Toast from '../../../components/toast';
import { setTitleDesc } from '../../../utils/tools'

let APK_DOWNLOAD_URL: any = 'https://res.youpin.mi-img.com/MiRetail-global-1-0.0.1-18-release.apk'

    
function DownloadsPage() {
    const [statusBarHeight, setStatusBarHeight] = useState(0);
    const [downloadUrl, setDownloadUrl] = useState(APK_DOWNLOAD_URL);

    useEffect(() => {
        setTitleDesc('Xiaomi Retail', '小米零售通')
        const params = [{
            appKey: 'lingshoutongglobal',
            configKey: ['h5_apk_download']
        }]
        
        nextWork.post(API_RetailDownloadsUrl, params).then((res: any) => {
            if (res && res.code === 0) {
                const { data } = res || {};
                const { h5_apk_download } = data || {};
                const linkData: { url: String; } = h5_apk_download;
                setDownloadUrl(linkData.url);
              } else {
                Toast.error(res?.message ?? 'Download failed, please try again later');
              }
        })
    }, []);

    const onDownLoad = () => {
        window.location.href = downloadUrl;
    }

    return (
        <div className={[globalStyles['container'], styles['viewport']].join(' ')}>
            <img className={styles['phone']} src={backgroundImg} alt='' />
            <img className={styles['footer']} src={footerImg} alt='' />
            <div className={styles['download_btn']} onClick={onDownLoad}>Download Now</div>
        </div>
    )
}

export default memo(DownloadsPage)