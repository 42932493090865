/*
 * @Author: liuhuazhou liuhuazhou@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: liuhuazhou liuhuazhou@xiaomi.com
 * @LastEditTime: 2024-07-17 16:06:45
 * @FilePath: /RetailApp/retail-global-h5/src/api/url.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 普通业务域名
const Domain_Path: any = {
    'development': '',
    'test': 'https://s-test.g.mi.com',
    'preview': 'https://s-pre.g.mi.com',
    'release': 'https://s.g.mi.com',

};
const domain = Domain_Path?.[process.env.BUILD_APPDOMAIN || 'development'];

// 国际业务域名
const Gobal_Domain_Path: any = {
    'development': '',
    'test': 'https://upc-test.nrapi.mi.com',
    'preview': 'https://upc-pre.nrapi.mi.com',
    'release': 'https://upc.nrapi.mi.com',

};
const global_domain = Gobal_Domain_Path?.[process.env.BUILD_APPDOMAIN || 'development'];

export const API_RetailDownloadsUrl = global_domain + (process.env.NODE_ENV === 'development' ? '/mtop/proretail/application/config/getConfigKey' : '/api/proretail/application/config/getConfigKey');

