/*
 * @Author: v-liangkai1 v-liangkai1@xiaomi.com
 * @Date: 2024-06-19 14:08:17
 * @LastEditors: v-liangkai1 v-liangkai1@xiaomi.com
 * @LastEditTime: 2024-07-22 10:13:04
 * @FilePath: src/pages/Channel/Downloads/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useState, useEffect } from 'react'
import globalStyles from '../../global.less'
import styles from './index.less'
import backgroundImg from './assets/phone_bg.png'
import footerImg from './assets/footer1.png'
import androidIcon from './assets/android_icon.png'
import iosIcon from './assets/ios_icon.png'
// import HanddImg from './assets/hand.png'
import { API_RetailDownloadsUrl } from '../../../api/url'
import nextWork from '../../../utils/net'
import Toast from '../../../components/toast'
import { setTitleDesc } from '../../../utils/tools'

function DownloadsPage() {
  const [androidUrl, setAndroidUrl] = useState('')
  const [iosUrl, setIosUrl] = useState('')

  useEffect(() => {
    setTitleDesc('Xiaomi Channel', '小米分销通')
    const params = [
      {
        appKey: 'fenxiaotong',
        configKey: ['h5_apk_download'],
      },
    ]
    console.log(API_RetailDownloadsUrl)
    nextWork.post(API_RetailDownloadsUrl, params).then((res: any) => {
      if (res && res.code === 0) {
        const { data } = res || {}
        const { h5_apk_download } = data || {}
        setAndroidUrl(h5_apk_download?.android_url)
        setIosUrl(h5_apk_download?.ios_url)
      } else {
        Toast.error(res?.message ?? 'Download failed, please try again later')
      }
    })
  }, [])

  const onDownLoad = (url: string) => {
    if(!url) {
      return
    }
    window.location.href = url
  }

  return (
    <div className={[globalStyles['container'], styles['viewport']].join(' ')}>
      <img className={styles['phone']} src={backgroundImg} alt="" />
      <img className={styles['footer']} src={footerImg} alt="" />
      {
        !!iosUrl && <div className={[styles['download_btn'], styles['download_btn--ios']].join(' ')} onClick={() => onDownLoad(iosUrl)}>
          <img className={styles['ios_icon']} src={iosIcon} alt="" />
          <span>Download IOS</span>
        </div>
      }
      {
        !!androidUrl && <div className={[styles['download_btn'], styles['download_btn--android']].join(' ')} onClick={() => onDownLoad(androidUrl)}>
          <img className={styles['android_icon']} src={androidIcon} alt="" />
          <span>Download Android</span>
        </div>
      }
      
    </div>
  )
}

export default memo(DownloadsPage)
